import Login from "views/examples/Login.js";
import Logout from "views/examples/Logout.js";
import ForgotPassword from "views/examples/signUp/forgotpassword.js";
import ConsumerIndex from "views/examples/consumer/consumerIndex.js";
import NewInstance from "views/examples/consumer/newInstance.js";
import newInstanceEnomos from "views/examples/consumer/newInstanceEnomos";
import ConsumerInstances from "views/examples/consumer/consumerInstances.js";
import ConsumerInstance from "views/examples/consumer/consumerInstance.js";
import ConsumerApplication from "views/examples/consumer/consumerApplication.js";
import CompleteApplication from "views/examples/consumer/completeApplication.js";
import ReviewInstance from "views/examples/consumer/reviewInstance.js";
import InstanceDetails from "views/examples/consumer/instanceDetails.js";
import InstanceProfile from "views/examples/consumer/InstanceProfile.js";
import InstanceDocuments from "views/examples/consumer/instanceDocuments.js";
import ConsumerDocuments from "views/examples/consumer/consumerDocuments.js";
import ConsumerProfile from "views/examples/consumer/consumerProfile.js";
import IndexSignUp from "views/examples/signUp/indexSignUp.js";
import ConsumerSignUp from "views/examples/signUp/consumerSignUp.js";
import ConsumerRepSignUp from "views/examples/signUp/consumerRepSignUp.js";
import ConsumerSettings from "views/examples/consumer/consumerSettings.js";
import ConsumerSupport from "views/examples/consumer/consumerSupport.js";
import SignUp from "views/examples/signUp/signup.js";
import OldInstances from "views/examples/consumer/OldInstances.js";
import mailinglist from "views/examples/consumer/mailingList.js";
import oldPosts from "views/examples/consumer/oldPosts.js";
import historyExport from "views/examples/consumer/historyExport.js";
import FAQ from "views/examples/consumer/FAQ";
import ConsumerInstancesEnomos from "views/examples/consumer/instancesEnomos.js";
import ConsumerInstanceEnomos from "views/examples/consumer/consumerInstanceEnomos.js";

var routes = [
  {
    path: "/faq",
    name: "FAQ",
    icon: "ni ni-circle-08 text-pink",
    component: FAQ,
    layout: "/consumer"
  },
  {
    path: "/index",
    name: "Αρχική",
    icon: "ni ni-circle-08 text-pink",
    component: ConsumerIndex,
    layout: "/consumer"
  },
  {
    path: "/newinstance",
    name: "Νέο αίτημα",
    icon: "ni ni-circle-08 text-pink",
    component: NewInstance,
    layout: "/consumer"
  },
  {
    path: "/newinstanceenomos/:id",
    name: "Αρχική",
    icon: "ni ni-circle-08 text-pink",
    component: newInstanceEnomos,
    layout: "/consumer"
  },
  {
    path: "/instances",
    name: "Αρχική",
    icon: "ni ni-circle-08 text-pink",
    component: ConsumerInstances,
    layout: "/consumer"
  },
  {
    path: "/instancesenomos",
    name: "Αρχική",
    icon: "ni ni-circle-08 text-pink",
    component: ConsumerInstancesEnomos,
    layout: "/consumer"
  },
  {
    path: "/instance/:id",
    name: "Αρχική",
    icon: "ni ni-circle-08 text-pink",
    component: ConsumerInstance,
    layout: "/consumer"
  },
  {
    path: "/instanceenomos/:id",
    name: "Αρχική",
    icon: "ni ni-circle-08 text-pink",
    component: ConsumerInstanceEnomos,
    layout: "/consumer"
  },
  {
    path: "/instanceprofile/:id",
    name: "Αρχική",
    icon: "ni ni-circle-08 text-pink",
    component: InstanceProfile,
    layout: "/consumer"
  },
  {
    path: "/application/:id",
    name: "Πρώιμη Αίτηση",
    icon: "ni ni-circle-08 text-pink",
    component: ConsumerApplication,
    layout: "/consumer"
  },
  {
    path: "/completeapplication/:id",
    name: "Πρώιμη Αίτηση",
    icon: "ni ni-circle-08 text-pink",
    component: CompleteApplication,
    layout: "/consumer"
  },
  {
    path: "/reviewinstance/:id",
    name: "Σύνοψη αίτησης",
    icon: "ni ni-circle-08 text-pink",
    component: ReviewInstance,
    layout: "/consumer"
  },
  {
    path: "/instancedetails/:id",
    name: "Σύνοψη αίτησης",
    icon: "ni ni-circle-08 text-pink",
    component: InstanceDetails,
    layout: "/consumer"
  },
  {
    path: "/instancedocuments/:id",
    name: "Σύνοψη αίτησης",
    icon: "ni ni-circle-08 text-pink",
    component: InstanceDocuments,
    layout: "/consumer"
  },
  {
    path: "/documents",
    name: "Αρχική",
    icon: "ni ni-circle-08 text-pink",
    component: ConsumerDocuments,
    layout: "/consumer"
  },
  {
    path: "/profile",
    name: "Αρχική",
    icon: "ni ni-circle-08 text-pink",
    component: ConsumerProfile,
    layout: "/consumer"
  },
  {
    path: "/settings",
    name: "Αρχική",
    icon: "ni ni-circle-08 text-pink",
    component: ConsumerSettings,
    layout: "/consumer"
  },
  {
    path: "/support",
    name: "Αρχική",
    icon: "ni ni-circle-08 text-pink",
    component: ConsumerSupport,
    layout: "/consumer"
  },
  {
    path: "/login",
    name: "Login",
    icon: "ni ni-key-25 text-info",
    component: Login,
    layout: "/auth"
  },
  {
    path: "/logout",
    name: "Logout",
    icon: "ni ni-key-25 text-info",
    component: Logout,
    layout: "/auth"
  },
  {
    path: "/OldInstances",
    name: "OldInstances",
    icon: "ni ni-key-25 text-info",
    component: OldInstances,
    layout: "/consumer"
  },
  // {
  //   path: "/index",
  //   name: "Register",
  //   icon: "ni ni-circle-08 text-pink",
  //   component: IndexSignUp,
  //   layout: "/signup"
  // },
  {
    path: "/consumer",
    name: "Register",
    icon: "ni ni-circle-08 text-pink",
    component: ConsumerSignUp,
    layout: "/signup"
  },
  {
    path: "/consumerrep",
    name: "Register",
    icon: "ni ni-circle-08 text-pink",
    component: ConsumerRepSignUp,
    layout: "/signup"
  },
  {
    path: "/index",
    name: "Register",
    icon: "ni ni-circle-08 text-pink",
    component: SignUp,
    layout: "/signup"
  },
  {
    path: "/index",
    name: "Register",
    icon: "ni ni-circle-08 text-pink",
    component: ForgotPassword,
    layout: "/forgotpassword"
  },
  {
    path: "/mailinglist",
    name: "mailinglist",
    icon: "ni ni-circle-08 text-pink",
    component: mailinglist,
    layout: "/consumer"
  },
  {
    path: "/oldposts",
    name: "oldPosts",
    icon: "ni ni-circle-08 text-pink",
    component: oldPosts,
    layout: "/consumer"
  },
  {
    path: "/historyexport",
    name: "historyExport",
    icon: "ni ni-circle-08 text-pink",
    component: historyExport,
    layout: "/consumer"
  }
];

export default routes;
