import React from 'react';
import { Row, Col, Input, Table, Button } from 'reactstrap';
import ReactTooltip from 'react-tooltip';
import Moment from 'moment'; // Ensure you have installed 'moment' package

const FileActionComponent = ({ dynamicActions, dynamicData, uploadFile, files, deleteFile }) => {
  return (
    <>
      <Row>
        <Col lg="10">
          <label className="btn btn-primary" htmlFor={dynamicData.step.Id + "/" + dynamicActions.action.Id}>
            Επιλογή αρχείου
          </label>
          &nbsp;
          <i className="fa fa-info-circle" data-tip data-for={'info-form-control-alternative' + dynamicData.step.Id + dynamicActions.action.Id} aria-hidden="true"></i>
          <ReactTooltip id={'info-form-control-alternative' + dynamicData.step.Id + dynamicActions.action.Id} type='info'>
            <span>Μέγιστο μέγεθος αρχείου 5ΜΒ.</span>
            <div id="imageattooltip"></div>
          </ReactTooltip>
          <Input
            className="form-control-alternative"
            id={dynamicData.step.Id + "/" + dynamicActions.action.Id}
            style={{ visibility: "hidden" }}
            type={dynamicActions.action.Type}
            disabled={!dynamicActions.action.Required}
            onChange={evt => uploadFile(evt, dynamicData.step.Id + "/" + dynamicActions.action.Id)}
          />
        </Col>
      </Row>

      <Table className="align-items-center table-flush" responsive>
        <thead className="thead-light">
          <tr>
            <th scope="col">Ονομα Αρχειου</th>
            <th scope="col">Ημερομηνια Αναρτησης</th>
            <th scope="col">Ενεργειες</th>
          </tr>
        </thead>
        <tbody>
          {
            files.map((dynamicFiles) =>
              <>
                {dynamicActions.action.Id == dynamicFiles.ActionId ?
                  <>
                    <tr>
                      <td>{dynamicFiles.FileName}</td>
                      <td>{Moment(dynamicFiles.UploadDate).locale('el').format('dddd, DD MMM YYYY h:mm:ss a')}</td>
                      <td>
                        <Button
                          color="primary"
                          href={dynamicFiles.Link}
                          target="_blank"
                          size="sm"
                          title="Προβολή"
                        >
                          <i className="fas fa-eye" />
                        </Button>
                        <Button
                          color="danger"
                          onClick={() => { deleteFile(dynamicFiles.Id) }}
                          size="sm"
                          title="Διαγραφή"
                        >
                          <i className="fas fa-trash" />
                        </Button>
                      </td>
                    </tr>
                  </> : null
                }
              </>
            )
          }
        </tbody>
      </Table>
    </>
  );
};

export default FileActionComponent;
