import React from "react";

// reactstrap components
import { Card, CardBody, CardTitle, Container, Row, Col } from "reactstrap";

import background from "../../assets/img/header-background.jpg";

class GenericHeader extends React.Component {
  render() {
    return (
      <>
        {/* <div className="header pb-4 pt-5" style={{background: "linear-gradient(to right, #FFFFFF, #4A002B)"}}> */}
        <div className="header pb-4 pt-5 " style={{backgroundImage: `url(${background})`}}>
          <Container fluid>
            <div className="header-body">
            </div>
          </Container>
        </div>
      </>
    );
  }
}

export default GenericHeader;
