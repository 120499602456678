import React from "react";
// node.js library that concatenates classes (strings)
import classnames from "classnames";

import axios from 'axios';

import {
  Button,
  Card,
  CardHeader,
  CardBody,
  NavItem,
  NavLink,
  Nav,
  Progress,
  Table,
  Container,
  Row,
  Col
} from "reactstrap";

import GenericHeader from "components/Headers/GenericHeader.js";
// import Spinner from "views/examples/Spinner.js";
import Moment from "moment";
import 'moment/locale/el';

import { MDBDataTable } from 'mdbreact';

import '../../../assets/css/datatable.css';


class ConsumerInstancesEnomos extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      userDetails: [],
      instances: [],
    };
  }

  componentDidMount() {
    if (localStorage.getItem('token')) {
      fetch(process.env.REACT_APP_API_LINK + "/api/auth/user/", {
        method: 'get',
        headers: new Headers({
          'Authorization': 'Bearer ' + localStorage.getItem('token'),
          'Content-Type': 'application/x-www-form-urlencoded'
        })
      })
        .then(res => res.json())
        .then((result) => {
          // console.log(result);
          this.setState({
            userDetails: result
          });
        })

      axios.get(process.env.REACT_APP_API_LINK + "/api/consumer/instances/enomos/current/", {
        headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
      })
        .then(res => {
          console.log(res.data);

          var WorkflowInstance_Table = [];

          res.data.forEach((item) => {
            WorkflowInstance_Table.push(item);
          });

          // var myuser = this.state.user.PersonRole;
          const instances2 = WorkflowInstance_Table;
          const self = this;
          instances2.forEach(function (part, index) {
            this[index].NewStatus = this[index].Status == 1 ? "1. Επεξεργασία Αιτήματος" : this[index].Status == 2 ? "2. Ολοκλήρωση Αιτήματος"  : "";
            this[index].Buttons =
              <React.Fragment>
                <Button
                  color="primary"
                  href={"/consumer/instanceenomos/" + this[index].Id}
                  size="sm"
                  title="Στοιχεία"
                >
                  Λεπτομέρειες
                </Button>
              </React.Fragment>

          }, instances2);
          this.state.datas = {
            columns: [
              {
                label: 'Αριθμος Αιτησης ▼',
                field: 'SerialNumber',
                sort: 'desc',
                width: 100,
                viewColumns: false
              },
              {
                label: 'Κατάσταση ▼',
                field: 'NewStatus',
                sort: 'asc',
                width: 100
              },
              {
                label: 'Ενέργειες ▼',
                field: 'Buttons',
                sort: 'asc',
                width: 100
              },
            ],
            rows: WorkflowInstance_Table,
          };

          this.setState({
            instances: WorkflowInstance_Table
          });
        })
    };
  }

  render() {
    return (
      <>
        <GenericHeader />
        {/* Page content */}
        <Container className="mt--8" fluid>
          <Row className="mt-5">
            <Col className="mb-5 mb-xl-0" xl="12">
              <Card className="shadow">
                <CardHeader className="border-0">
                  <Row className="align-items-center">
                    <Col xs="8">
                      <h3 className="mb-0">Τα αιτήματά μου</h3>
                    </Col>
                  </Row>

                </CardHeader>
                <CardHeader className="border-0">
                  <Row className="align-items-center">
                    <div className="col">
                      <MDBDataTable
                        bordered
                        exportToCSV
                        hover
                        responsive
                        strip
                        data={this.state.datas}
                        id="mdbdttable"
                        entriesOptions={[10, 25, 50, 100, 500, 1000]} entries={10}
                        noDataComponent="Δεν υπάρχουν δεδομένα"
                        searchLabel="Αναζήτηση"
                        paginationLabel={['Προηγούμενο', 'Επόμενο']}
                        infoLabel={['Εμφανίζονται', 'έως', 'από', 'καταχωρήσεις']}
                        entriesLabel={'Εμφάνιση Αιτημάτων ανά: '}
                      />
                    </div>

                  </Row>
                </CardHeader>
              </Card>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

export default ConsumerInstancesEnomos;
