import React from "react";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col
} from "reactstrap";
// core components
import GenericHeader from "components/Headers/GenericHeader.js";

class User extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      activeNav: 1,
      isLoaded: false,
      user: [],
      roles: []
    };
  }

  componentDidMount() {
    if (localStorage.getItem('token')) {
      fetch(process.env.REACT_APP_API_LINK + "/api/consumer/profile/", {
        method: 'get',
        headers: new Headers({
          'Authorization': 'Bearer ' + localStorage.getItem('token'),
          'Content-Type': 'application/x-www-form-urlencoded'
        })
      })
        .then(res => res.json())
        .then(
          (result) => {
            this.setState({
              isLoaded: true,
              user: result,
            });
          },
          (error) => {
            this.setState({
              isLoaded: true,
              error
            });
          }
        )
    };
  }

  changeName = (e) => {
    this.state.user.Name = e.target.value;
  }

  changeLastName = (e) => {
    this.state.user.LastName = e.target.value;
  }

  changeEmail = (e) => {
    this.state.user.Email = e.target.value;
  }

  phoneNumber = (e) => {
    this.state.user.phoneNumber = e.target.value;
  }

  updateChanges = () => {
    fetch(process.env.REACT_APP_API_LINK + "/api/consumer/update/", {
      method: 'put',
      headers: new Headers({
        'Authorization': 'Bearer ' + localStorage.getItem('token'),
        'Content-Type': 'application/x-www-form-urlencoded'
      }),
      body: JSON.stringify({
        'Name': this.state.user.Name,
        'LastName': this.state.user.LastName,
        'Email': this.state.user.Email,
        'phoneNumber': this.state.user.phoneNumber
      })
    })
      .then(res => res.json())
      .then(
        (result) => {
          if(result.status != 'email_exists')
            window.location.reload();
          else
          {
            alert('A user with this email already exists!');
            window.location.reload();
          }
            
        },
        (error) => {

        }
      )
  }



  render() {
    return (
      <>
        <GenericHeader />
        {/* Page content */}
        <Container className="mt--7" fluid>
          <Row>
            <Col className="order-xl-1" xl="12">
              <Card className="bg-secondary shadow">
                <CardHeader className="bg-white border-0">
                  <Row className="align-items-center">
                    <Col xs="8">
                      <h3 className="mb-0">Πληροφορίες χρήστη</h3>
                    </Col>
                    <Col className="text-right" xs="4">
                      <Button
                        color="primary"
                        onClick={() => { this.updateChanges() }}
                        size="sm"
                      >
                        Αποθήκευση αλλαγών
                      </Button>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody>
                  <Form>
                    <h6 className="heading-small text-muted mb-4">
                      Βασικα στοιχεια
                    </h6>
                    <div className="pl-lg-4">
                      <Row>
                        <Col lg="3">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-username"
                            >
                              'Ονομα
                            </label>
                            <Input
                              className="form-control-alternative"
                              defaultValue={this.state.user.Name}
                              id="input-username"
                              placeholder=""
                              type="text"
                              onChange={e => { this.changeName(e) }}
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="3">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-email"
                            >
                              Επίθετο
                            </label>
                            <Input
                              className="form-control-alternative"
                              defaultValue={this.state.user.LastName}
                              id="input-email"
                              placeholder="email@example.com"
                              type="text"
                              onChange={e => { this.changeLastName(e) }}
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="3">
                          <FormGroup>
                            {String(this.state.user.Email).includes("@facebook.com") ?
                              <>
                                <label
                                  className="form-control-label"
                                  htmlFor="input-first-name"
                                >
                                  Email
                                </label>
                                <Input
                                  className="form-control-alternative"
                                  defaultValue={""}
                                  id="input-first-name"
                                  placeholder="Email"
                                  type="email"
                                  onChange={e => { this.changeEmail(e) }}
                                >
                                </Input>
                              </>
                              :
                              <>
                                <label
                                  className="form-control-label"
                                  htmlFor="input-first-name"
                                >
                                  Email
                                </label>
                                <Input
                                  className="form-control-alternative"
                                  defaultValue={this.state.user.Email}
                                  id="input-first-name"
                                  placeholder="First name"
                                  type="email"
                                  onChange={e => { this.changeEmail(e) }}
                                >
                                </Input>
                              </>
                            }
                          </FormGroup>
                        </Col>
                        <Col lg="3">
                          <FormGroup>
                            {String(this.state.user.phoneNumber) == "0000000000" ?
                              <>
                                <label
                                  className="form-control-label"
                                  htmlFor="input-username"
                                >
                                  Τηλέφωνο
                                </label>
                                <Input
                                  className="form-control-alternative"
                                  defaultValue={""}
                                  id="input-username"
                                  placeholder="Αριθμός Τηλεφώνου"
                                  type="text"
                                  onChange={e => { this.phoneNumber(e) }}
                                />
                              </>
                              :
                              <>
                                <label
                                  className="form-control-label"
                                  htmlFor="input-username"
                                >
                                  Τηλέφωνο
                                </label>
                                <Input
                                  className="form-control-alternative"
                                  defaultValue={this.state.user.phoneNumber}
                                  id="input-username"
                                  placeholder=""
                                  type="text"
                                  onChange={e => { this.phoneNumber(e) }}
                                />
                              </>
                            }
                          </FormGroup>
                        </Col>
                      </Row>

                      {this.state.user.consumerType == 2 ?
                        <Row>
                          <Col lg="4">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-username"
                              >
                                Επωνυμία Φορέα
                              </label>
                              <Input
                                className="form-control-alternative"
                                defaultValue={this.state.user.companyName}
                                id="input-username"
                                placeholder=""
                                type="text"
                              />
                            </FormGroup>
                          </Col>
                          <hr className="my-4" />
                        </Row>
                        :
                        <p></p>
                      }

                    </div>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

export default User;
