import React from "react";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Label,
  Col,
  Alert
} from "reactstrap";
// core components
import GenericHeader from "components/Headers/GenericHeader.js";

class ConsumerSupport extends React.Component {
    constructor(props){
        super(props);
        this.state = {
          error: null,
          activeNav: 1,
          isLoaded: false,
          user: [],
          ticketSent: 0,
          category: '',
          message: ''
        };

        this.changeCategory = this.changeCategory.bind(this);
        this.changeMessage = this.changeMessage.bind(this);
    }
    
    componentDidMount() {
        if (localStorage.getItem('token')) {
            fetch(process.env.REACT_APP_API_LINK + "/api/auth/user/", {
            method: 'get',
            headers: new Headers({
                'Authorization' : 'Bearer ' + localStorage.getItem('token'),
                'Content-Type': 'application/x-www-form-urlencoded'
            })
            })
            .then(res => res.json())
            .then(
                (result) => {
                    this.setState({
                    isLoaded: true,
                    user: result,
                    });
                },
                (error) => {
                    this.setState({
                    isLoaded: true,
                    error
                    });
                }
            )
        };
    }

    sendTicket = applicationId => {
        if (localStorage.getItem('token')) { 
            fetch(process.env.REACT_APP_API_LINK + '/api/support/new/', {
                method: 'POST',
                headers: new Headers({
                    'Authorization' : 'Bearer ' + localStorage.getItem('token'),
                }),
                body: JSON.stringify({
                    'Message' : this.state.message,
                    'Subject' : this.state.category
                })
            })
            .then(data => {
                // console.log(data);
                this.setState({
                    ticketSent: 1
                })

                setTimeout(function() {
                    window.location.replace("/consumer/index/")
                }, 3500);
            })
        }
    }

    changeCategory(event) {
        // console.log(event.target.value);
		this.setState({category: event.target.value});
	}

    changeMessage(event) {
        // console.log(event.target.value);
		this.setState({message: event.target.value});
	}

    render() {
        return (
            <>
            {this.state.ticketSent ?
                <>
                    <GenericHeader />
                    {/* Page content */}
                    <Container className="mt--7" fluid>
                        <Row>
                            <Col className="order-xl-1" xl="12">
                                <Card className="bg-secondary shadow">
                                    <CardHeader className="bg-white border-0">
                                        <Row className="align-items-center">
                                            <Col xs="8">
                                            <h3 className="mb-0">Υποστήριξη (support)</h3>
                                            </Col>
                                        </Row>
                                    </CardHeader>
                                    <CardBody>
                                        <p style={{color: 'green'}}><strong>Το μήνυμά σας καταχωρήθηκε με επιτυχία ! Θα ενημερωθείτε σύντομα για την εξέλιξή του.</strong></p>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </> 
                :
                    <>
                    <GenericHeader />
                    {/* Page content */}
                    <Container className="mt--7" fluid>
                    <Row>
                        <Col className="order-xl-1" xl="12">
                            <Card className="bg-secondary shadow">
                                <CardHeader className="bg-white border-0">
                                    <Row className="align-items-center">
                                        <Col xs="8">
                                        <h3 className="mb-0">Υποστήριξη (support)</h3>
                                        </Col>
                                    </Row>
                                </CardHeader>
                                <CardBody>
                                    <Form>
                                        <h6 className="heading-small text-muted mb-4">
                                            Ενημερωστε μας για το προβλημα σας
                                        </h6>
                                        <div className="pl-lg-4">
                                        <Row>
                                            <Col lg="12">
                                            <FormGroup>
                                                    <Label for="exampleText">Κατηγορία Προβλήματος</Label>
                                                    <Input
                                                        className="form-control-alternative"
                                                        id="consumer-client"
                                                        type="select"
                                                        onChange={this.changeCategory}
                                                    >   
                                                        <option>-- Επιλέξτε κατηγορία προβλήματος --</option>
                                                        <option>Αδυναμία υποβολής στοιχείων σε αίτηση</option>
                                                        <option>Τεχνικό πρόβλημα στο σύστημα</option>
                                                        <option>Απορία σχετικά με την αίτησή μου</option>
                                                        <option>Άλλο</option>
                                                    </Input>
                                                </FormGroup>
                                            </Col>

                                            <Col lg="12">
                                                <FormGroup>
                                                    <Label for="exampleText">Μήνυμα</Label>
                                                    <Input 
                                                        type="textarea" 
                                                        name="text" 
                                                        id="exampleText"
                                                        onChange={this.changeMessage}
                                                        style={{height: 200}}
                                                    />
                                                </FormGroup>

                                                <Button 
                                                    color="primary"
                                                    onClick={() => { this.sendTicket() }}
                                                >
                                                    Αποστολή
                                                </Button>
                                            </Col>
                                        </Row>
                                        </div>
                                    </Form>

                                    {/* <hr className="my-4" />

                                    <Form>
                                        <h6 className="heading-small text-muted mb-4">
                                            Εναλλακτικος τροπος επικοινωνιας
                                        </h6>
                                        <div className="pl-lg-4">
                                            <Row>
                                                <Col lg="12">
                                                    <p><strong>Email : &nbsp;</strong>info@rae.gr</p>
                                                    <p><strong>Τηλέφωνο : &nbsp;</strong></p>
                                                </Col>
                                            </Row>
                                        </div>
                                    </Form> */}

                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    </Container>
                </>
            }
            </>
        );
    }
}

export default ConsumerSupport;
