import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle } from '@fortawesome/free-regular-svg-icons';

const FAQ = () => {
    return (
        <div style={{ maxWidth: '800px', margin: '0 auto' }}>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: '20px' }}>
                <FontAwesomeIcon icon={faQuestionCircle} style={{ width: '32px', height: '32px', marginRight: '10px' }} />
                <h3 style={{ margin: '0', fontSize: '32px' }}>Συχνές Ερωτήσεις</h3>
            </div>
            <div style={{ backgroundColor: '#f5f5f5', padding: '20px', borderRadius: '10px', boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)' }}>
                <p style={{ marginBottom: '10px', fontSize: '20px', fontWeight: 'bold' }}>Ερώτηση: Ποιο είναι το νόημα της ζωής;</p>
                <p style={{ fontSize: '18px' }}>Απάντηση: Αυτό είναι ένα δύσκολο ερώτημα... θα σας απαντήσουμε μόλις τη βρούμε.</p>
                {/* add more Q&A pairs as needed */}
            </div>
            <br></br>
            {/* add more sections as needed */}
            <div style={{ backgroundColor: '#f5f5f5', padding: '20px', borderRadius: '10px', boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)', marginTop: '20px' }}>
                <p style={{ marginBottom: '10px', fontSize: '20px', fontWeight: 'bold' }}>Ερώτηση: Πως μπορώ να επαναφέρω τον κωδικό μου;</p>
                <p style={{ fontSize: '18px' }}>Απάντηση: Μπορείτε να επαναφέρετε τον κωδικό σας κάνοντας κλικ στον σύνδεσμο "Ξέχασα τον κωδικό μου" στη σελίδα σύνδεσης και ακολουθώντας τις οδηγίες.</p>
            </div>
        </div>
    );
};

export default FAQ;
