import React from 'react';
import { Row, Col } from 'reactstrap';
import ReactTooltip from 'react-tooltip';
import { Cascader } from 'antd';

const DropdownActionComponent = ({ dynamicData, dynamicActions, updateInputValueCascader }) => {
  return (
    <React.Fragment>
      <i className="fa fa-info-circle" data-tip data-for={'info-dropdown' + dynamicData.step.Id + dynamicActions.action.Id} aria-hidden="true"></i>
      <Row>
        <ReactTooltip id={'info-dropdown' + dynamicData.step.Id + dynamicActions.action.Id} type='info'>
          <span>{dynamicActions.action.Description}</span>
        </ReactTooltip>

        <Col md={2}>
          <Cascader
            id={(dynamicData.step.Id + "/" + dynamicActions.action.Id)}
            className={"dropdown"}
            options={JSON.parse(dynamicActions.action.Target)}
            onChange={(value) => updateInputValueCascader(value, dynamicActions)}
            placeholder={"Επιλέξτε ΝΑΙ ή ΟΧΙ"}
            style={{ width: '100%' }}
          />
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default DropdownActionComponent;
