import React from "react";

import Modal from 'react-modal';

import '../../../assets/css/signup.css';

// reactstrap components
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    FormGroup,
    Form,
    Input,
    Container,
    Row,
    Col,
    ModalHeader,
    ModalBody,
    ModalFooter
} from "reactstrap";
// core components
import GenericHeader from "components/Headers/GenericHeader.js";
import { ThemeProvider } from "react-bootstrap";
// import { tokenToString } from "typescript";

class ForgotPassword extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            email: "",
            phoneNumber: "",
            verificationcode: "",
            errormsg: 0,
            getInitialState: false,
            close: false,
            open: false,
            showModal1: false,
            showModal2: false,
            fullfilledEmail: false,
            fullfilledPhoneNumber: false,
            fullfilledPassword: false,
            fullfilledPasswordConfirmation: false,
            tokensms: "",
            newpassword1: "",
            newpassword2: "",
            country_code: ''
        };
        this.changeEmail = this.changeEmail.bind(this);
        this.changeverificationcode = this.changeverificationcode.bind(this);
        this.sendcodetoVerify = this.sendcodetoVerify.bind(this);
        this.tokensms = this.changetokensms.bind(this);
        this.changePassword1 = this.changePassword1.bind(this);
        this.changePassword2 = this.changePassword2.bind(this);

        this.close = this.close.bind(this);
        this.open = this.open.bind(this);
        this.close2 = this.close2.bind(this);
        this.open2 = this.open2.bind(this);
    }

    changetokensms(event) {
        //console.log(event.target.value);
        this.setState({ tokensms: event.target.value })
    }

    close() {
        this.setState({ showModal: false });
    }

    open() {
        //alert(document.getElementById('input-username').value);
        this.setState({ showModal: true });
    }
    close2() {
        this.setState({ showModal2: false });
    }

    open2() {
        //alert(document.getElementById('input-username').value);
        this.setState({ showModal2: true });
        document.getElementById('passChangeBtn').setAttribute('disabled', '');
    }
    componentDidMount() {
        //onload
        //get data from api
        //etc
    }

    changeverificationcode(event) {
        if (event.target.value.trim() == '') {
            let input_password_validation_repeat_error = document.getElementById('input-password-validation-repeat-error');
            input_password_validation_repeat_error.innerHTML = 'Το πεδίο είναι απαραίτητο';
            input_password_validation_repeat_error.style.display = "block";
            this.setState({ fullfilledPasswordConfirmation: false });
            document.getElementById('submit-button-register').setAttribute('disabled', '');

            //console.log(this.state.fullfilledPasswordConfirmation);
        }

        // else if (event.target.value != this.state.password1) {
        //   let input_password_validation_repeat_error = document.getElementById('input-password-validation-repeat-error');
        //   input_password_validation_repeat_error.innerHTML = 'Οι κωδικοί δεν ταιριάζουν';
        //   input_password_validation_repeat_error.style.display = "block";
        //   this.setState({ fullfilledPasswordConfirmation: false });
        //   document.getElementById('submit-button-register').setAttribute('disabled', '');

        //   console.log(this.state.fullfilledPasswordConfirmation);
        // }

        // else if (event.target.value == this.state.password1) {
        //   document.getElementById('input-password-validation-repeat-error').style.display = "none";
        //   this.setState({ fullfilledPasswordConfirmation: true });

        //   if (this.allFieldsFullFilled())
        //     document.getElementById('submit-button-register').removeAttribute('disabled');
        //   else
        //     document.getElementById('submit-button-register').setAttribute('disabled', '');
        // }

        // console.log(event.target.value);
        this.setState({ verificationcode: event.target.value });
    }

    sendcodetoVerify(event, code) {
        this.setState({ verificationcode: event.target.value });
        { this.RequestSMSVerificationCode() }
        if (code.value.trim() == "") {
            document.getElementById("statusmessage").innerHTML = "<p style='color:red'>Πρέπει να συμπληρώσετε το πεδίο του κωδικού.</p>";
        }
        else {
            document.getElementById("statusmessage").innerHTML = "<p style='color:green'>Ο κωδικός που ππληκτρολογήσατε είναι ο" + code.value + "</p>";
        }

        //alert(code);
    }

    changeEmail(event) {
        var validRegex = /^\S+@\S+\.\S+$/;
        document.getElementById('submit-button-register').setAttribute('disabled', '');
        if (event.target.value.trim() == '') {
            let email_validation_error_element = document.getElementById('email-validation-error');
            email_validation_error_element.innerHTML = 'Το πεδίο είναι απαραίτητο';
            email_validation_error_element.style.display = "block";
            this.setState({ fullfilledEmail: false });
            document.getElementById('submit-button-register').setAttribute('disabled', '');
        }

        else if (!event.target.value.match(validRegex)) {
            let email_validation_error_element = document.getElementById('email-validation-error');
            email_validation_error_element.innerHTML = 'Το email δεν είναι έγκυρο';
            email_validation_error_element.style.display = "block";
            this.setState({ fullfilledEmail: false });
            document.getElementById('submit-button-register').setAttribute('disabled', '');
        }

        else {
            document.getElementById('email-validation-error').style.display = "none";
            this.setState({ fullfilledEmail: true });
            document.getElementById('submit-button-register').removeAttribute('disabled');
            //this.allFieldsFullFilled();
        }

        // console.log(event.target.value);
        this.setState({ email: event.target.value });
    }
    //   changePhoneNumber(event) {
    //       document.getElementById('input-phonenumber-validation-error').style.display = "none";
    //       this.setState({ fullfilledPhoneNumber: true });

    //       if (this.state.fullfilledEmail == true) {
    //         document.getElementById('submit-button-register').removeAttribute('disabled');
    //       }
    //     // console.log(event.target.value);
    //   }
    UpdatePassword = id => {
        if (!localStorage.getItem('token')) {
            fetch(process.env.REACT_APP_API_LINK + '/api/consumer/newpassword/', {
                method: 'POST',
                body: JSON.stringify({
                    'Email': this.state.email,
                    'Password': this.state.newpassword1,
                    'phoneNumber': this.state.phoneNumber
                })
            })
                .then(data => {
                    // console.log(data);
                    // this.setState({
                    //     ticketSent: 1
                    // })
                    setTimeout(function () {
                        //alert("OK");
                        window.location.replace("/consumer/index/")
                    }, 3500);
                })
        }
    }
    NewPassword = applicationId => {
        this.close();
        this.open2();
    }
    CheckifUserEmailExists = nothing => {
        fetch(process.env.REACT_APP_API_LINK + '/api/sms/emailexists/', {
            method: 'POST',
            // headers: new Headers({
            //     'Authorization' : 'Bearer ' + localStorage.getItem('token'),
            // }),
            body: JSON.stringify({
                'email': this.state.email
            })
        })
            .then(res => res.json())
            .then(data => {
                console.log(data);
                if (data.Exists) {
                    //alert(data.PhoneNumber);
                    this.RequestSMSVerificationCode(data.PhoneNumber);
                    this.setState({ country_code: data.CountryCode})
                }
                else if (!data.Exists) {
                    document.getElementById('email-validation-error').innerHTML = '<p style="color:red;">Ο χρήστης με το email δεν υπάρχει. Δοκιμάστε ξανά.</p>';
                    document.getElementById('email-validation-error').style.display = 'block';
                }
                // var verificationcode = document.getElementById("").value;
                // alert(verificationcode);
                //this.VerifyCode(data.token);
                setTimeout(function () {
                    //window.location.replace("/consumer/index/")
                }, 3500);
            })
    }

    RequestSMSVerificationCode = phoneNumber => {
        // alert(process.env.REACT_APP_API_LINK);
        if (phoneNumber.trim() != "") {
            fetch(process.env.REACT_APP_API_LINK + '/api/sms/token/', {
                method: 'POST',
                // headers: new Headers({
                //     'Authorization' : 'Bearer ' + localStorage.getItem('token'),
                // }),
                body: JSON.stringify({
                    'client': process.env.REACT_APP_SMS_CREDS_CLIENT,
                    'secret': process.env.REACT_APP_SMS_CREDS_SECRET
                })
            })
                .then(res => res.json())
                .then(data => {
                    // console.log(data);
                    // var verificationcode = document.getElementById("").value;
                    // alert(verificationcode);
                    this.setState({ phoneNumber: phoneNumber });
                    this.VerifyCode(data.token);
                    setTimeout(function () {
                        //window.location.replace("/consumer/index/")
                    }, 3500);
                })
            this.open();
        }
        else {
            alert("No Number");
        }
    }
    VerifyCode = tokensms => {
        fetch(process.env.REACT_APP_API_LINK + '/api/sms/validatesend/', {
            method: 'POST',
            body: JSON.stringify({
                'client': process.env.REACT_APP_SMS_CREDS_CLIENT,
                'secret': process.env.REACT_APP_SMS_CREDS_SECRET,
                'token': tokensms,
                'email': this.state.email,
                'phone': this.state.phoneNumber,
                'country_code': this.state.country_code
            })
        })
            .then(res => res.json())
            .then(data => {
                console.log(data);
                this.state.tokensms = tokensms;
                this.setState({
                    ticketSent: 1
                })
                setTimeout(function () {
                    //window.location.replace("/consumer/index/")
                }, 3500);
            })
    }
    ValidateCode = tokensms => {
        fetch(process.env.REACT_APP_API_LINK + '/api/sms/validateotp/', {
            method: 'POST',
            body: JSON.stringify({
                'client': process.env.REACT_APP_SMS_CREDS_CLIENT,
                'secret': process.env.REACT_APP_SMS_CREDS_SECRET,
                'token': this.state.tokensms,
                'email': this.state.email,
                'phone': this.state.phoneNumber,
                'country_code': this.state.country_code,
                'otp': this.state.verificationcode
            })
        })
            .then(res => res.json())
            .then(data => {
                // console.log(data.status);
                if (data.status != "ERROR") {
                    this.NewPassword();
                    //   document.getElementById("statusmessage").innerHTML = "<p style='color:green'>Ο χρήστης δημιουργήθηκε με επιτυχία. Μπορείτε να κάνετε login <a href='../../'>ΕΔΩ</a><p>";
                    //   document.getElementById("btnValidate6Digit").disabled = true;
                }
                else {
                    document.getElementById("statusmessage").innerHTML = "<p style='color:red'>Ο κωδικός που εισάγατε δεν είναι σωστός. Παρακαλούμε ξαναδοκιμάστε.<p>";
                    document.getElementById("btnValidate6Digit").disabled = false;
                    //this.close;
                }
                this.setState({
                    ticketSent: 1
                })
                setTimeout(function () {
                    //window.location.replace("/consumer/index/")
                }, 3500);
            })
    }

    allFieldsFullFilled() {
        if (this.state.fullfilledEmail == true) {
            document.getElementById('submit-button-register').removeAttribute('disabled');
            return true;
        }
        else {
            document.getElementById('submit-button-register').setAttribute('disabled', '');
            return false;
        }
    }
    passfilled() {
        if (this.state.fullfilledPassword == true) {
            document.getElementById('passChangeBtn').removeAttribute('disabled');
            return true;
        }
        else {
            document.getElementById('passChangeBtn').setAttribute('disabled', '');
            return false;
        }
    }
    changePassword1(event) {
        document.getElementById("message").style.display = "block";

        let letter_complete = false;
        let capital_complete = false;
        let number_complete = false;
        let length_complete = false;


        var letter = document.getElementById("letter");
        var capital = document.getElementById("capital");
        var number = document.getElementById("number");
        var length = document.getElementById("length");

        if (event.target.value.trim() == '') {
            document.getElementById("message").style.display = "none";
            let input_password_validation_error = document.getElementById('input-password-validation-error');
            input_password_validation_error.innerHTML = 'Το πεδίο είναι απαραίτητο';
            input_password_validation_error.style.display = "block";
            this.setState({ fullfilledPassword: false });
            document.getElementById('passChangeBtn').setAttribute('disabled', '');
        }

        else {
            
            if(event.target.value != this.state.newpassword2)
            {
                document.getElementById('passChangeBtn').setAttribute('disabled', '');
            }

            document.getElementById("message").style.display = "block";
            var lowerCaseLetters = /[a-z]/g;
            if (event.target.value.match(lowerCaseLetters)) {
                letter.classList.remove("invalid");
                letter.classList.add("valid");

                letter_complete = true;
            } else {
                letter.classList.remove("valid");
                letter.classList.add("invalid");

                letter_complete = false;
            }

            var upperCaseLetters = /[A-Z]/g;
            if (event.target.value.match(upperCaseLetters)) {
                capital.classList.remove("invalid");
                capital.classList.add("valid");

                capital_complete = true;
            } else {
                capital.classList.remove("valid");
                capital.classList.add("invalid");

                capital_complete = false;
            }

            // Validate numbers
            var numbers = /[0-9]/g;
            if (event.target.value.match(numbers)) {
                number.classList.remove("invalid");
                number.classList.add("valid");

                number_complete = true;
            } else {
                number.classList.remove("valid");
                number.classList.add("invalid");

                number_complete = false;
            }

            // Validate length
            if (event.target.value.length >= 8) {
                length.classList.remove("invalid");
                length.classList.add("valid");

                length_complete = true;
            } else {
                length.classList.remove("valid");
                length.classList.add("invalid");

                length_complete = false;
            }

            document.getElementById('input-password-validation-error').style.display = "none";

            if (
                (letter_complete == true) &&
                (number_complete == true) &&
                (capital_complete == true) &&
                (length_complete == true)
            ) {
                this.setState({ fullfilledPassword: true });
            }

            else
                this.setState({ fullfilledPassword: false });


            if (this.state.fullfilledPasswordConfirmation) {
                if (event.target.value.trim() != this.state.verificationcode) {
                    const flag = this.passfilled();

                    if (flag)
                        document.getElementById('submit-button-register').setAttribute('disabled', '');
                }
                else {
                    const flag = this.passfilled();

                    if (flag) {
                        if (
                            (letter_complete == true) &&
                            (number_complete == true) &&
                            (capital_complete == true) &&
                            (length_complete == true)
                        )
                            document.getElementById('passChangeBtn').removeAttribute('disabled');
                    }

                    else
                        document.getElementById('passChangeBtn').setAttribute('disabled', '');
                }
            }


            // console.log(this.state.verificationcode);

        }


        // console.log(event.target.value);
        this.setState({ newpassword1: event.target.value });
    }

    changePassword2(event) {
        // console.log(event.target.value);

        this.setState({ newpassword2: event.target.value });

        //H pano grammi den douleuei sync.
        if (this.state.newpassword1.toString() != event.target.value) {
            document.getElementById('passChangeBtn').setAttribute('disabled', '');
            this.setState({ errormsg: 1 })
        }
        else {
            document.getElementById('passChangeBtn').removeAttribute('disabled');
            // console.log("OK");
            this.setState({ errormsg: 0 })
        }
    }

    render() {

        window.onload = () => {
            document.getElementById('submit-button-register').setAttribute('disabled', '');
        };

        return (
            <>
                <GenericHeader />
                {/* Page content */}
                <Container className="mt--9" fluid>
                    <Row>
                        <Col className="order-xl-1" xl="12">
                            <Card className="bg-secondary shadow">
                                <CardHeader className="bg-white border-0">
                                    <Row className="align-items-center">
                                        <Col xs="8">
                                            <h3 className="mb-0">Αλλαγή Κωδικού</h3>
                                        </Col>
                                        <Col className="text-right" xs="4">
                                            {/* <Button
                        color="success"
                        size="sm"
                        id="submit-button-register"
                        onClick={() => { this.RequestSMSVerificationCode(); }}
                      >
                        <span className="fa fa-eye"></span>&nbsp;Εγγραφή
                            </Button> */}
                                        </Col>
                                    </Row>
                                </CardHeader>
                                <CardBody>
                                    <Form>
                                        <h6 className="heading-small text-muted mb-4">
                                            Επιβεβαιωση Email
                                        </h6>
                                        <div className="pl-lg-4">
                                            <Row>
                                                <Col lg="4"></Col>
                                                <Col lg="4">
                                                    <FormGroup>

                                                        <label
                                                            className="form-control-label"
                                                            htmlFor="input-email"
                                                        >
                                                            Email
                                                        </label>

                                                        <Input
                                                            className="form-control-alternative"
                                                            defaultValue=""
                                                            id="input-email"
                                                            placeholder="email@example.com"
                                                            type="email"
                                                            onChange={this.changeEmail}
                                                        />
                                                        <span id={"email-validation-error"} style={{ color: "red", display: "none" }}></span>
                                                    </FormGroup>
                                                </Col>
                                                <Col lg="4"></Col>
                                            </Row>
                                        </div>
                                        {/* <div className='password_messages_container'>
                      <div id="message" style={{ width: '400px'}}>
                        <h3>Ο κωδικός πρέπει να περιέχει:</h3>
                        <p id="letter" className="invalid"><b>Πεζούς</b> χαρακτήρες</p>
                        <p id="capital" className="invalid"><b>Κεφαλαίους</b> χαρακτήρες</p>
                        <p id="number" className="invalid">Τουλάχιστον 1 <b>αριθμό</b></p>
                        <p id="length" className="invalid">Τουλάχιστον <b>8 ψηφία</b></p>
                      </div>
                    </div> */}


                                        <br />
                                        <div
                                            style={{ display: 'flex', justifyContent: 'center' }}
                                        >
                                            <Button
                                                color="success"
                                                size="lg"
                                                id="submit-button-register"
                                                onClick={() => { this.CheckifUserEmailExists(); }}

                                            >
                                                <span className="fa fa-arrow-right"></span>&nbsp;Επιβεβαίωση Χρήστη
                                            </Button>
                                        </div>

                                    </Form>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>


                <Modal
                    id="Mdl1"
                    isOpen={this.state.showModal}
                    ariaHideApp={false}
                    contentLabel="Info Modal"
                    style={{
                        overlay: {

                        },
                        content: {
                            position: 'absolute',
                            // top: '30%',
                            // left: '30%',
                            // marginTop: '-50px',
                            // marginLeft: '-30px',
                            // width: '45%',
                            // height: '45%'
                            margin: '0 auto',
                            maxWidth: '80%',
                            width: '700px',
                            height: '510px'
                        }
                    }}
                >
                    <ModalHeader>Επιβεβαίωση Χρήστη</ModalHeader>
                    <ModalBody>
                        <Container>
                            <Col md="12" style={{ alignTextAlign: "center" }}>
                                <FormGroup>
                                    <label
                                        className="form-control-label"
                                        htmlFor="input-address"
                                        size="large"
                                    >
                                        Σας έχει αποσταλλεί στο κινητό που δηλώσατε ένας 6ψήφιος κωδικός μεσω SMS.
                                        Παρακαλούμε συμπληρώστε τον στο παρακάτω πεδίο.
                                    </label>
                                    <Input
                                        id="txtcodetoverifySMS"
                                        type="text"
                                        placeholder="6-ψήφιος κωδικός"
                                        maxLength="6"
                                        className="form-control-alternative"
                                        defaultValue=""
                                        onChange={this.changeverificationcode}
                                    />
                                    <br />
                                    <Button
                                        type="button"
                                        onClick={this.ValidateCode}
                                        className="btn btn-success"
                                        id="btnValidate6Digit">

                                        Επιβεβαίωση
                                    </Button>
                                    <div id="statusmessage"></div>
                                </FormGroup>
                            </Col>
                        </Container>
                    </ModalBody>
                    <ModalFooter>
                        <Button
                            color="primary"
                            onClick={this.close}
                            size="sm"
                            title="Κλείσιμο"
                        >
                            <i className="fas fa-times" /> &nbsp; Κλείσιμο
                        </Button>
                    </ModalFooter>
                </Modal>
                <Modal
                    id="Mdl2"
                    isOpen={this.state.showModal2}
                    ariaHideApp={false}
                    contentLabel="Info Modal"
                    style={{
                        overlay: {

                        },
                        content: {
                            position: 'absolute',
                            // top: '30%',
                            // left: '30%',
                            // marginTop: '-50px',
                            // marginLeft: '-30px',
                            // width: '45%',
                            // height: '45%'
                            margin: '0 auto',
                            maxWidth: '80%',
                            width: '700px',
                            height: '510px'
                        }
                    }}
                >
                    <ModalHeader>Νέος Κωδικός</ModalHeader>
                    <ModalBody>
                    
                        <Container>
                            <Col md="12" style={{ alignTextAlign: "center" }}>
                                <FormGroup>
                                    <hr className="my-4" />
                                    <h6 className="heading-small text-muted mb-4">
                                        Κωδικος προσβασης
                                    </h6>
                                    <div className="pl-lg-4">
                                        <Row>
                                            <Col md="2"></Col>
                                            <Col md="8" style={{ alignTextAlign: "center" }}>
                                                <FormGroup>
                                                    <label
                                                        className="form-control-label"
                                                        htmlFor="input-password"
                                                    >
                                                        Νέος κωδικός πρόσβασης
                                                    </label>
                                                    <Input
                                                        className="form-control-alternative"
                                                        defaultValue=""
                                                        id="input-password"
                                                        type="password"
                                                        pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}"
                                                        onChange={this.changePassword1}
                                                    />
                                                    <span id={"input-password-validation-error"} style={{ color: "red", display: "none" }}></span>
                                                </FormGroup>
                                            </Col>

                                        </Row>

                                        <div className='password_messages_container'>
                                            <div id="message" style={{ width: '400px' }}>
                                                <h3>Ο κωδικός πρέπει να περιέχει:</h3>
                                                <p id="letter" className="invalid"><b>Πεζούς</b> χαρακτήρες</p>
                                                <p id="capital" className="invalid"><b>Κεφαλαίους</b> χαρακτήρες</p>
                                                <p id="number" className="invalid">Τουλάχιστον 1 <b>αριθμό</b></p>
                                                <p id="length" className="invalid">Τουλάχιστον <b>8 ψηφία</b></p>
                                            </div>
                                        </div>
                                        <Row>
                                            <Col md="2"></Col>
                                            <Col md="8" style={{ alignTextAlign: "center" }}>
                                                <FormGroup>
                                                    <label
                                                        className="form-control-label"
                                                        htmlFor="input-password-verification"
                                                    >
                                                        Επιβεβαίωση νέου κωδικού πρόσβασης
                                                    </label>
                                                    <Input
                                                        className="form-control-alternative"
                                                        defaultValue=""
                                                        id="input-password-verification"
                                                        type="password"
                                                        onChange={this.changePassword2}
                                                    />
                                                    <span id={"input-password-validation-repeat-error"} style={{ color: "red", display: "none" }}></span>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        {this.state.errormsg ?
                                            <Row>
                                                <Col md="2"></Col>
                                                <Col md="8" style={{ alignTextAlign: "center" }}>
                                                    <p style={{ color: "red" }}>Οι Κωδικοί δεν ταιριαζουν.</p>
                                                </Col>
                                            </Row> : <></>
                                        }
                                    </div>
                                    <br />
                                    <Button
                                        type="button"
                                        onClick={this.UpdatePassword}
                                        className="btn btn-success"
                                        id="passChangeBtn">

                                        Επιβεβαίωση
                                    </Button>

                                    
                                </FormGroup>
                            </Col>
                        </Container>
                    </ModalBody>
                    <ModalFooter>
                        <Button
                            color="primary"
                            onClick={this.close2}
                            size="sm"
                            title="Κλείσιμο"
                        >
                            <i className="fas fa-times" /> &nbsp;Άκυρο
                        </Button>
                    </ModalFooter>
                </Modal>
            </>
        );
    }
}

export default ForgotPassword;