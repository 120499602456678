import React, { useState } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

import ConsumerLayout from "layouts/Consumer.js";
import SignUpLayout from "layouts/SignUp.js";
import ForgotPasswordLayout from "layouts/ForgotPassword.js";

import Login from "views/examples/Login.js";
import SignUp from "layouts/SignUp";import { config } from '@fortawesome/fontawesome-svg-core';

// add this line to set up FontAwesome
config.autoAddCss = false;

function setToken(userToken) {
    localStorage.setItem('token', userToken);
}

async function verifyToken(token) {
    await fetch(process.env.REACT_APP_API_LINK + "/api/auth/user/", {
        method: 'get',
        headers: new Headers({
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/x-www-form-urlencoded'
        })
    })
        //.then(res => res.json())
        .then(
            (result) => {
                // // console.log(result.status);
                if (result.status != 200) {
                    localStorage.removeItem('token');
                    window.location.replace("/auth/login");
                }
                else {
                    localStorage.setItem('token', token);
                }
            },
            (error) => {
                // // console.log(error);
                localStorage.removeItem('token');
                window.location.replace("/auth/login");
            }
        )
};

function App() {
    const token = localStorage.getItem('token');

    // // console.log(token);

    if (!token) {
        if (window.location.href.includes('signup')) {
            // // console.log(window.location.href);
            return (
                <BrowserRouter>
                    <Switch>
                        <Route path="/signup" render={props => <SignUpLayout {...props} />} />
                    </Switch>
                </BrowserRouter>
            )
        }
        else if (window.location.href.includes('forgotpassword')) {
            // // console.log(window.location.href);
            return (
                <BrowserRouter>
                    <Switch>
                        <Route path="/forgotpassword" render={props => <ForgotPasswordLayout {...props} />} />
                    </Switch>
                </BrowserRouter>
            )
        }
        else {
            return <Login setToken={setToken} />
        };

    }
    else {
        verifyToken(token);
        // // console.log(token);

        return (
            <BrowserRouter>
                <Switch>
                    <Route path="/auth" render={props => <Login {...props} />} />
                    <Route path="/consumer" render={props => <ConsumerLayout {...props} />} />
                    <Route path="/signup" render={props => <SignUpLayout {...props} />} />
                    <Route path="/forgotpassword" render={props => <ForgotPasswordLayout {...props} />} />
                    <Redirect from="/" to="/consumer/index" />
                </Switch>
            </BrowserRouter>
        );
    }
}

export default App;
