/*!

=========================================================
* Argon Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col
} from "reactstrap";
// core components
import GenericHeader from "components/Headers/GenericHeader.js";

class ConsumerRepSignUp extends React.Component {
    constructor(props){
        super(props);
        this.state = {
          error: null,
          activeNav: 1,
          isLoaded: false,
          user: [],
          roles: []
        };
      }
    
      componentDidMount() {
      }

  render() {
    return (
      <>
        <GenericHeader />
        {/* Page content */}
        <Container className="mt--9" fluid>
          <Row>
            <Col className="order-xl-1" xl="12">
              <Card className="bg-secondary shadow">
                <CardHeader className="bg-white border-0">
                  <Row className="align-items-center">
                    <Col xs="8">
                      <h3 className="mb-0">Εγγραφή νέου χρήστη</h3>
                    </Col>
                    <Col className="text-right" xs="4">
                      <Button
                        color="primary"
                        href="#pablo"
                        onClick={e => e.preventDefault()}
                        size="sm"
                      >
                        Αποθήκευση αλλαγών
                      </Button>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody>
                  <Form>
                  <h6 className="heading-small text-muted mb-4">
                      Βασικα στοιχεια
                    </h6>
                    <div className="pl-lg-4">
                      <Row>
                        <Col lg="4">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-username"
                            >
                              Όνομα
                            </label>
                            <Input
                              className="form-control-alternative"
                              defaultValue=""
                              id="input-username"
                              placeholder="Όνομα"
                              type="text"
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="4">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-email"
                            >
                              Επίθετο 
                            </label>
                            <Input
                              className="form-control-alternative"
                              defaultValue=""
                              id="input-email"
                              placeholder="Επίθετο"
                              type="text"
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="4">
                        <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-email"
                            >
                              Email 
                            </label>
                            <Input
                              className="form-control-alternative"
                              defaultValue=""
                              id="input-email"
                              placeholder="email@example.com"
                              type="email"
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                    </div>
                    <hr className="my-4" />
                    <h6 className="heading-small text-muted mb-4">
                      Κωδικος προσβασης
                    </h6>
                    <div className="pl-lg-4">
                      <Row>
                        <Col md="4"></Col>
                        <Col md="4" style={{alignTextAlign: "center"}}>
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-address"
                            >
                              Νέος κωδικός πρόσβασης
                            </label>
                            <Input
                              className="form-control-alternative"
                              defaultValue=""
                              id="input-address"
                              type="password"
                            />
                          </FormGroup>
                        </Col>
                        
                      </Row>
                      <Row>
                            <Col md="4"></Col>
                            <Col md="4" style={{alignTextAlign: "center"}}>
                                <FormGroup>
                                    <label
                                    className="form-control-label"
                                    htmlFor="input-address"
                                    >
                                    Επιβεβαίωση νέου κωδικού πρόσβασης
                                    </label>
                                    <Input
                                    className="form-control-alternative"
                                    defaultValue=""
                                    id="input-address"
                                    type="password"
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                    </div>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

export default ConsumerRepSignUp;
