import React, { useState } from "react";
import Select from 'react-select'
// node.js library that concatenates classes (strings)
import classnames from "classnames";

import { Cascader } from 'antd';
import 'antd/dist/antd.css';

import { CommonLoading } from 'react-loadingg';

import axios from 'axios';

import ReactTooltip from 'react-tooltip';

import background from "../../../assets/img/header-background.jpg";

import '../../../assets/css/signup.css';

import {
    Button,
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    CardFooter,
    Form,
    FormGroup,
    Table,
    Input,
    Container,
    Row,
    Col
} from "reactstrap";

import InstanceHeader from "components/Headers/InstanceHeader.js";
import GenericHeader from "components/Headers/GenericHeader.js";
import Accordion from 'react-bootstrap/Accordion';
import Moment from "moment";
import 'moment/locale/el';

function importAll(r) {
    let images = {};
    r.keys().map((item, index) => { images[item.replace('./', '')] = r(item); });
    return images;
}

const images_tooltips_images = importAll(require.context('../tooltipimages/', false, /\.(png|jpe?g|svg)$/));

class InstanceDetails extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            options: [],
            error: null,
            activeNav: 1,
            loading: true,
            instance: [],
            steps: [],
            files: [],
            enabled: true,
            fileUpload: null,
            buttonCondition: 0,
            consumerType: 0,
            consumerCategory: 0,
            type: '',
            selectedUse: '',
            firstFilled: false,
            secondFilled: false,
            thirdFilled: false,
            allFilled: false
        };

        this.changeType = this.changeType.bind(this);
        this.changeCategory = this.changeCategory.bind(this);
        this.updateInputValueCascader = this.updateInputValueCascader.bind(this);
    }

    async componentDidMount() {
        const handle = this.props.match.params.id;
        // console.log(handle);

        const result = await axios.get(process.env.REACT_APP_API_LINK + "/api/workflowinstances/instance/" + handle + "/", {
            headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
        })
            .then(res => {
                this.setState({
                    instance: res.data.instance,
                    steps: res.data.stepsInfused,
                    fileUpload: null,
                    consumerType: 0,
                    consumerCategory: 0,
                    loading: false,
                });
                // console.log(res);
            })
        fetch(process.env.REACT_APP_API_LINK + '/api/images/getimagebyinstanceid/' + handle + "/", {
            method: 'GET',
            headers: new Headers({
                'Authorization': 'Bearer ' + localStorage.getItem('token'),
            })
        })
            .then(res => res.json())
            .then(data => {
                // console.log('image');
                // console.log(data);
                try {
                    document.getElementById("imageattooltip").innerHTML = "<img src=" + images_tooltips_images[data].default + " style='width:400px;' alt='Image'/>"
                }
                catch {

                }
            })
        try {
            document.getElementById('submit-button-1').setAttribute('disabled', '');
            document.getElementById('submit-button-2').setAttribute('disabled', '');
        }
        catch {

        }
    };

    saveInstance = stepStatus => {
        // console.log(this.state.steps);

        if (localStorage.getItem('token')) {
            fetch(process.env.REACT_APP_API_LINK + '/api/workflowinstances/update/' + this.state.instance.Id + '/', {
                method: 'POST',
                headers: new Headers({
                    'Authorization': 'Bearer ' + localStorage.getItem('token'),
                }),
                body: JSON.stringify(this.state.steps[stepStatus - 1])
            })
                .then(data => {
                    // console.log(data);
                    window.location.replace("/consumer/index");
                })
        }
    }

    deleteInstance = stepStatus => {
        if (localStorage.getItem('token')) {
            axios.delete(process.env.REACT_APP_API_LINK + '/api/workflowinstances/' + this.state.instance.Id + "/", {
                headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
            })
                .then(res => {
                    window.location.replace("/consumer/index");
                })
        }
    }

    submitInstance = stepStatus => {
        // console.log(this.state.steps);

        if (localStorage.getItem('token')) {
            fetch(process.env.REACT_APP_API_LINK + '/api/workflowinstances/submit/' + this.state.instance.Id + '/', {
                method: 'POST',
                headers: new Headers({
                    'Authorization': 'Bearer ' + localStorage.getItem('token'),
                }),
                body: JSON.stringify(this.state.steps[stepStatus - 1])
            })
                .then(data => {
                    // console.log(data);
                    window.location.reload(false);
                })
        }
    }

    updateFile(evt, test) {
        // console.log(test);
        this.setState({ fileUpload: evt.target.files[0] });
    }

    deleteFile = fileId => {
        if (localStorage.getItem('token')) {
            axios.delete(process.env.REACT_APP_API_LINK + '/api/files/' + fileId + "/", {
                headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
            })
                .then(res => {
                    axios.get(process.env.REACT_APP_API_LINK + "/api/files/status/" + this.state.instance.Id + "/", {
                        headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
                    })
                        .then(res => {
                            this.setState({
                                loading: false,
                                files: res.data
                            });
                            // console.log(res);
                        })
                })
        }
    }

    dynamicCall(call) {
        // console.log(call);
        if (call == 'accept_answer') {
            // console.log('accept');

            fetch(process.env.REACT_APP_API_LINK + "/api/dynamic/acceptresponse/" + this.state.instance.Id + '/', {
                method: 'get',
                headers: new Headers({
                    'Authorization': 'Bearer ' + localStorage.getItem('token'),
                    'Content-Type': 'application/x-www-form-urlencoded'
                })
            })
                .then(res => res.json())
                .then(data => {
                    // console.log(data);
                });

            window.location.replace("/consumer/index/");

            // this.setState({
            //     buttonMessage: 'Αιτηθήκατε για αυτοψία. Θα ενημερωθεί το αρμόδιο τμήμα για το αίτημά σας.',
            //     buttonCondition: 8
            // });
        }
        else if (call == 'decline_answer') {
            // console.log('decline');

            fetch(process.env.REACT_APP_API_LINK + "/api/dynamic/declineresponse/" + this.state.instance.Id + '/', {
                method: 'get',
                headers: new Headers({
                    'Authorization': 'Bearer ' + localStorage.getItem('token'),
                    'Content-Type': 'application/x-www-form-urlencoded'
                })
            })
                .then(res => res.json())
                .then(data => {
                    // console.log(data);
                });

            window.location.replace("/consumer/index/");

            // this.setState({
            //     buttonMessage: 'Αιτηθήκατε για αυτοψία. Θα ενημερωθεί το αρμόδιο τμήμα για το αίτημά σας.',
            //     buttonCondition: 8
            // });
        }
    }

    uploadFile(evt, actionid) {
        const formData = new FormData();
        var res = actionid.split("/");

        // Update the formData object 
        formData.append(
            "files",
            evt.target.files[0]
        );

        // Details of the uploaded file 
        // console.log(evt.target.files[0]);
        // console.log(formData);


        fetch(
            process.env.REACT_APP_FILEMANAGER_LINK,
            {
                method: 'POST',
                body: formData,
            }
        )
            .then((response) => response.json())
            .then((result) => {
                // console.log('Success:', result);

                fetch(process.env.REACT_APP_API_LINK + '/api/files/new/', {
                    method: 'POST',
                    headers: new Headers({
                        'Authorization': 'Bearer ' + localStorage.getItem('token'),
                        // 'Authorization': 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJjcm1hZG1pbkBkZWRhLmdyIiwiZXhwIjoxNjI0OTcyNzExfQ.EPp7fXdyHnPUuQGili4QwH1-FNJo_I0z-M-eNdYSSTg',
                    }),
                    body: JSON.stringify({
                        'Name': result[0].name,
                        'Path': result[0].path,
                        'Size': result[0].size,
                        'Type': result[0].type,
                        'InstanceId': this.state.instance.Id,
                        'StepId': res[0],
                        'ActionId': res[1]
                    })
                })
                    .then(data => {
                        // console.log(data);

                        axios.get(process.env.REACT_APP_API_LINK + "/api/files/status/" + this.state.instance.Id + "/", {
                            headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
                            // headers: { Authorization: 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJjcm1hZG1pbkBkZWRhLmdyIiwiZXhwIjoxNjI0OTcyNzExfQ.EPp7fXdyHnPUuQGili4QwH1-FNJo_I0z-M-eNdYSSTg'}
                        })
                            .then(res => {
                                this.setState({
                                    loading: false,
                                    files: res.data
                                });
                                // console.log(res);
                            })
                    })
            })
            .catch((error) => {
                console.error('Error:', error);
            });

            
    }

    updateInputValue(evt) {
        var res = evt.target.id.split("/");
        let data = [...this.state.steps];

        function checkifFilled(item) {
            return item.data.Data !== '';
        }
        // console.log(res);

        var i;
        for (i = 0; i < (this.state.steps).length; i++) {
            if (this.state.steps[i].step.Id == res[0]) {
                let checkAction = this.state.steps[i].actions.slice(0, 9)
                let checkRequired = checkAction.filter(element => {
                    if (element.action.Required) return element
                })
                var j;
                for (j = 0; j < (this.state.steps[i].actions).length; j++) {
                    if (this.state.steps[i].actions[j].action.Id == res[1]) {
                        let tempAction = { ...data[i].actions[j] };

                        tempAction.data.Data = evt.target.value;
                        data[i].actions[j] = tempAction;

                        this.setState({
                            steps: data
                        });
                    }
                }
                checkRequired.forEach(element => {
                    if (element.data.Data == '') {
                        document.getElementById('submit-button-1').setAttribute('disabled', '');
                        document.getElementById('submit-button-2').setAttribute('disabled', '');
                    }
                })
                if (checkRequired.every(checkifFilled)) {
                    document.getElementById('submit-button-1').removeAttribute('disabled');
                    document.getElementById('submit-button-2').removeAttribute('disabled');
                }
            }
        }

    }

    updateInputValueNumber(evt, dynamicactions) {

        if (dynamicactions.action.Target == 'limit14') {
            if ((evt.target.value).length > 14) {
                evt.target.value = evt.target.value.substring(0, evt.target.value.length - 1);
            }
        }

        if (dynamicactions.action.Target == 'limit9') {
            if ((evt.target.value).length > 9) {
                evt.target.value = evt.target.value.substring(0, evt.target.value.length - 1);
            }
        }

        var res = evt.target.id.split("/");
        let data = [...this.state.steps];
        function checkifFilled(item) {
            return item.data.Data !== '';
        }
        // console.log(res);

        var i;
        for (i = 0; i < (this.state.steps).length; i++) {
            if (this.state.steps[i].step.Id == res[0]) {
                let checkAction = this.state.steps[i].actions.slice(0, 9)
                let checkRequired = checkAction.filter(element => {
                    if (element.action.Required) return element
                })
                var j;
                for (j = 0; j < (this.state.steps[i].actions).length; j++) {
                    if (this.state.steps[i].actions[j].action.Id == res[1]) {
                        let tempAction = { ...data[i].actions[j] };

                        tempAction.data.Data = evt.target.value;
                        data[i].actions[j] = tempAction;

                        this.setState({
                            steps: data
                        });
                    }
                }
                checkRequired.forEach(element => {
                    if (element.data.Data == '' && element.action.Required) {
                        document.getElementById('submit-button-1').setAttribute('disabled', '');
                        document.getElementById('submit-button-2').setAttribute('disabled', '');
                    }
                })
                if (checkRequired.every(checkifFilled)) {
                    document.getElementById('submit-button-1').removeAttribute('disabled');
                    document.getElementById('submit-button-2').removeAttribute('disabled');
                }
            }
        }
    }

    updateInputValueCascader(value) {
        var res = (document.getElementsByClassName("dropdown")[2].innerHTML.substring(77, 150)).split("/");

        let temp = '';
        var i;
        for (i = 0; i < value.length; i++) {
            temp += value[i] + ' / ';
        }
        // console.log(temp);

        let data = [...this.state.steps];
        function checkifFilled(item) {
            return item.data.Data !== '';
        }
        for (i = 0; i < (this.state.steps).length; i++) {
            if (this.state.steps[i].step.Id == res[0]) {
                let checkAction = this.state.steps[i].actions.slice(0, 9)
                let checkRequired = checkAction.filter(element => {
                    if (element.action.Required) return element
                })
                var j;
                for (j = 0; j < (this.state.steps[i].actions).length; j++) {
                    if (this.state.steps[i].actions[j].action.Id == res[1]) {
                        let tempAction = { ...data[i].actions[j] };

                        tempAction.data.Data = temp;
                        data[i].actions[j] = tempAction;

                        this.setState({
                            steps: data
                        });
                    }
                }
                checkRequired.forEach(element => {
                    if (element.data.Data == '' && element.action.Required) {
                        document.getElementById('submit-button-1').setAttribute('disabled', '');
                        document.getElementById('submit-button-2').setAttribute('disabled', '');
                    }
                })
                if (checkRequired.every(checkifFilled)) {
                    document.getElementById('submit-button-1').removeAttribute('disabled');
                    document.getElementById('submit-button-2').removeAttribute('disabled');
                }
            }
        }
    }

    changeType(event) {
        // console.log(event.target.value);
        this.setState({ consumerType: event.target.value });
    }

    changeCategory(event) {
        // console.log(event.target.value);
        this.setState({ consumerCategory: event.target.value });
    }

    onDropdownSelected = (e) => {
        this.state.selectedUse = e.target.value;
        // console.log(this.state.selectedUse);
    }

    GetImageByInstanceId = (instanceid) => {
        fetch(process.env.REACT_APP_API_LINK + '/api/images/getimagebyinstanceid/' + instanceid, {
            method: 'GET',
            headers: new Headers({
                'Authorization': 'Bearer ' + localStorage.getItem('token'),
            })
            // body: JSON.stringify({
            //   'fromdate': '2018-05-05',
            //   'todate': '2022-05-05'
            // })
        })
            .then(res => res.json())
            .then(data => {
                alert(data);
                // console.log(data);
                document.getElementById("imageattooltip").innerHTML = "<img src=" + images_tooltips_images[data].default + " style='width:400px;' alt='Image'/>"
                // this.setState({
                //     data_statsbyenergykind: data
                // })
            })
    }

    render() {
        if (this.state.loading) {
            return (
                <>
                    <GenericHeader />
                    <CommonLoading />
                </>
            )
        }
        else {
            return (
                <>
                    <InstanceHeader />
                    {/* <div className="header pb-4" style={{ background: "linear-gradient(to right, #FFFFFF, #4A002B)" }}> */}
                    <div className="header pb-4" style={{ backgroundImage: `url(${background})` }}>
                        <Container fluid>
                            <div className="header-body">
                                <Row>
                                    <Col lg="8" xl="12">
                                        <Card className="card-stats mb-4 mb-xl-0">
                                            <CardBody>
                                                <Row>
                                                    <div className="col">
                                                        <CardTitle
                                                            tag="h5"
                                                            className="text-uppercase text-muted mb-0"
                                                        >
                                                        </CardTitle>
                                                        <span className="h2 font-weight-bold mb-0">
                                                            Υποβολή Αιτήματος
                                                        </span>
                                                        <CardBody>
                                                            <Row className="align-items-center"><span style={{ fontWeight: "bold" }}>Αριθμός Αιτήματος: &nbsp; </span> {this.state.instance.SerialNumber}</Row>
                                                            <Row className="align-items-center"><span style={{ fontWeight: "bold" }}>Ημερομηνία Υποβολής: &nbsp; </span> {Moment(this.state.instance.StartedAt).locale('el').format('dddd, DD MMM YYYY')}</Row>
                                                        </CardBody>
                                                    </div>
                                                </Row>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                </Row>
                            </div>
                        </Container>
                    </div>

                    {this.state.steps.map((dynamicData) =>
                        <>
                            {dynamicData.step.Status == this.state.instance.Status ?
                                <>
                                    {dynamicData.step.Conditional && !this.state.instance.Paused ?
                                        <Container className="mt-2" fluid>
                                            <Row className="mt-5">
                                                <Col className="order-xl-1" xl="12">
                                                    <Card className="bg-secondary shadow">
                                                        <CardHeader className="bg-white border-0">
                                                            <Row className="align-items-center">
                                                                <Col xs="8">
                                                                    <h3 className="mb-0">{dynamicData.step.Name}</h3>
                                                                </Col>
                                                                <Col className="text-right" xs="4">
                                                                    <Button
                                                                        color="danger"
                                                                        onClick={() => { this.deleteInstance() }}
                                                                        size="sm"
                                                                    >
                                                                        Ακύρωση
                                                                    </Button>
                                                                    <Button
                                                                        color="success"
                                                                        onClick={() => { this.submitInstance(dynamicData.step.Status) }}
                                                                        size="sm"
                                                                        id="submit-button-1"
                                                                    >
                                                                        Υποβολή
                                                                    </Button>
                                                                </Col>
                                                            </Row>
                                                        </CardHeader>
                                                        <CardBody>
                                                            <Form>
                                                                <div className="pl-lg-4">
                                                                    <Row>
                                                                        {dynamicData.actions.map((dynamicActions) =>
                                                                            <>
                                                                                {dynamicActions.action.RunTime == 'consumer' ?
                                                                                    <Col lg="12">
                                                                                        <FormGroup>
                                                                                            {/* {this.state.instance.Status == 2 ? 
                                                                            <> </> : <>  */}
                                                                                            <label
                                                                                                className="form-control-label"
                                                                                                htmlFor="input-username"
                                                                                            >
                                                                                                {dynamicActions.action.Name}
                                                                                            </label>
                                                                                            {/* </>} */}
                                                                                            {dynamicActions.action.Type == 'file' ?
                                                                                                <>
                                                                                                    <Row>
                                                                                                        <Col lg="10">
                                                                                                            <label className="btn btn-primary" htmlFor={dynamicData.step.Id + "/" + dynamicActions.action.Id}>
                                                                                                                Επιλογή αρχείου
                                                                                                            </label>
                                                                                                            &nbsp;
                                                                                                            <i class="fa fa-info-circle" data-tip data-for={'info-form-control-alternative' + dynamicData.step.Id + dynamicActions.action.Id} aria-hidden="true"></i>
                                                                                                            <ReactTooltip id={'info-form-control-alternative' + dynamicData.step.Id + dynamicActions.action.Id} type='info'>
                                                                                                                <span>Μέγιστο μέγεθος αρχείου 5ΜΒ.</span>
                                                                                                                <div id="imageattooltip"></div>
                                                                                                            </ReactTooltip>
                                                                                                            <Input
                                                                                                                className="form-control-alternative"
                                                                                                                // defaultValue={ dynamicActions.data.Data }
                                                                                                                id={dynamicData.step.Id + "/" + dynamicActions.action.Id}
                                                                                                                // placeholder={dynamicActions.action.Description}
                                                                                                                style={{ visibility: "hidden" }}
                                                                                                                type={dynamicActions.action.Type}
                                                                                                                disabled={!dynamicActions.action.Required}
                                                                                                                onChange={evt => this.uploadFile(evt, dynamicData.step.Id + "/" + dynamicActions.action.Id)}
                                                                                                            />
                                                                                                        </Col>
                                                                                                        {/* <Button
                                                                                                            color="primary"
                                                                                                            onClick={evt => this.uploadFile(evt, dynamicData.step.Id + "/" + dynamicActions.action.Id)}
                                                                                                            size="sm"
                                                                                                        >
                                                                                                            Επισύναψη
                                                                                                        </Button> */}
                                                                                                    </Row>

                                                                                                    <Table className="align-items-center table-flush" responsive>
                                                                                                        <thead className="thead-light">
                                                                                                            <tr>
                                                                                                                <th scope="col">Ονομα Αρχειου</th>
                                                                                                                <th scope="col">Ημερομηνια Αναρτησης</th>
                                                                                                                <th scope="col">Ενεργειες</th>
                                                                                                            </tr>
                                                                                                        </thead>
                                                                                                        <tbody>
                                                                                                            {
                                                                                                                this.state.files.map((dynamicFiles) =>
                                                                                                                    <>
                                                                                                                        {dynamicActions.action.Id == dynamicFiles.ActionId ?
                                                                                                                            <>
                                                                                                                                <tr>
                                                                                                                                    <td>{dynamicFiles.FileName}</td>
                                                                                                                                    <td>{Moment(dynamicFiles.UploadDate).locale('el').format('dddd, DD MMM YYYY h:mm:ss a')}</td>
                                                                                                                                    <td>
                                                                                                                                        <Button
                                                                                                                                            color="primary"
                                                                                                                                            href={dynamicFiles.Link}
                                                                                                                                            target="_blank"
                                                                                                                                            size="sm"
                                                                                                                                            title="Προβολή"
                                                                                                                                        >
                                                                                                                                            <i className="fas fa-eye" />
                                                                                                                                        </Button>
                                                                                                                                        <Button
                                                                                                                                            color="danger"
                                                                                                                                            onClick={() => { this.deleteFile(dynamicFiles.Id) }}
                                                                                                                                            size="sm"
                                                                                                                                            title="Διαγραφή"
                                                                                                                                        >
                                                                                                                                            <i className="fas fa-trash" />
                                                                                                                                        </Button>
                                                                                                                                    </td>
                                                                                                                                </tr>
                                                                                                                            </> : <></>
                                                                                                                        }
                                                                                                                    </>
                                                                                                                )}
                                                                                                        </tbody>
                                                                                                    </Table>
                                                                                                    {/* </> } */}
                                                                                                </>
                                                                                                : <>{dynamicActions.action.Type == 'button' ?
                                                                                                    <>
                                                                                                        <Col lg="6">
                                                                                                            <Button
                                                                                                                color="primary"
                                                                                                                onClick={evt => this.dynamicCall(dynamicActions.action.Target)}
                                                                                                                size="sm"
                                                                                                            >
                                                                                                                {dynamicActions.action.Description}
                                                                                                            </Button>
                                                                                                        </Col>
                                                                                                        {/* {this.state.buttonCondition == dynamicData.step.Status 
                                                                                                            ? 
                                                                                                                <p>{this.state.buttonMessage}</p>
                                                                                                            :   <> {this.state.buttonCondition == 5 ? <p>{this.state.buttonMessage}</p> : <></>} </> 
                                                                                                        } */}
                                                                                                    </>
                                                                                                    : <>{dynamicActions.action.Type == 'textarea' ?
                                                                                                        <React.Fragment>
                                                                                                            &nbsp;<i class="fa fa-info-circle" data-tip data-for={'info-textarea' + dynamicData.step.Id + dynamicActions.action.Id} aria-hidden="true"></i>
                                                                                                            <ReactTooltip id={'info-textarea' + dynamicData.step.Id + dynamicActions.action.Id} type='info'>
                                                                                                                <span>{dynamicActions.action.Description}</span>
                                                                                                            </ReactTooltip>
                                                                                                            <Input
                                                                                                                className="form-control-alternative"
                                                                                                                defaultValue={dynamicActions.data.Data}
                                                                                                                id={dynamicData.step.Id + "/" + dynamicActions.action.Id}
                                                                                                                placeholder={dynamicActions.action.Description}
                                                                                                                type='textarea'
                                                                                                                disabled={!dynamicActions.action.Required}
                                                                                                                onChange={evt => this.updateInputValue(evt)}
                                                                                                            />
                                                                                                        </React.Fragment>

                                                                                                        : <> {dynamicActions.action.Type == 'number' ?
                                                                                                            <React.Fragment>
                                                                                                                <i class="fa fa-info-circle" data-tip data-for={'info-form-control-alternative' + dynamicData.step.Id + dynamicActions.action.Id} aria-hidden="true"></i>
                                                                                                                <Input
                                                                                                                    className="form-control-alternative"
                                                                                                                    defaultValue={dynamicActions.data.Data}
                                                                                                                    id={dynamicData.step.Id + "/" + dynamicActions.action.Id}
                                                                                                                    placeholder={dynamicActions.action.Description}
                                                                                                                    type={dynamicActions.action.Type}
                                                                                                                    disabled={!dynamicActions.action.Required}
                                                                                                                    onChange={evt => this.updateInputValueNumber(evt, dynamicActions)}

                                                                                                                />

                                                                                                                <ReactTooltip id={'info-form-control-alternative' + dynamicData.step.Id + dynamicActions.action.Id} type='info'>
                                                                                                                    <span>{dynamicActions.action.Description}</span>
                                                                                                                    {dynamicActions.action.Description == 'Αριθμός Παροχής' ?
                                                                                                                        <>
                                                                                                                            <div id="imageattooltip"></div>
                                                                                                                        </> :
                                                                                                                        <>
                                                                                                                        </>

                                                                                                                    }

                                                                                                                </ReactTooltip>
                                                                                                            </React.Fragment>




                                                                                                            : <> {dynamicActions.action.Type == 'dropdown' ?

                                                                                                                <React.Fragment>
                                                                                                                    <i class="fa fa-info-circle" data-tip data-for={'info-dropdown' + dynamicData.step.Id + dynamicActions.action.Id} aria-hidden="true"></i>
                                                                                                                    <Row>
                                                                                                                        <Col lg="10">
                                                                                                                            <ReactTooltip id={'info-dropdown' + dynamicData.step.Id + dynamicActions.action.Id} type='info'>
                                                                                                                                <span>{dynamicActions.action.Description}</span>

                                                                                                                                {/* <p>Δοκιμαστικό κείμενο</p> */}
                                                                                                                            </ReactTooltip>
                                                                                                                            <Cascader id={(dynamicData.step.Id + "/" + dynamicActions.action.Id)} className={"dropdown"} options={JSON.parse(dynamicActions.action.Target)} onChange={this.updateInputValueCascader} placeholder={"Επιλέξτε κατηγορία"} style={{ width: '100%' }} />
                                                                                                                        </Col>
                                                                                                                    </Row>
                                                                                                                </React.Fragment>
                                                                                                                :
                                                                                                                <React.Fragment>
                                                                                                                    <i class="fa fa-info-circle" data-tip data-for={'info-form-control-alternative' + dynamicData.step.Id + dynamicActions.action.Id} aria-hidden="true"></i>
                                                                                                                    <Input
                                                                                                                        className="form-control-alternative"
                                                                                                                        defaultValue={dynamicActions.data.Data}
                                                                                                                        id={dynamicData.step.Id + "/" + dynamicActions.action.Id}
                                                                                                                        placeholder={dynamicActions.action.Description}
                                                                                                                        type={dynamicActions.action.Type}
                                                                                                                        disabled={!dynamicActions.action.Required}
                                                                                                                        onChange={evt => this.updateInputValue(evt)}

                                                                                                                    />


                                                                                                                    <ReactTooltip id={'info-form-control-alternative' + dynamicData.step.Id + dynamicActions.action.Id} type='info'>
                                                                                                                        <span>{dynamicActions.action.Description}</span>

                                                                                                                        {/* <div id="imageattooltip"></div> */}
                                                                                                                    </ReactTooltip>

                                                                                                                </React.Fragment>



                                                                                                            }
                                                                                                            </>
                                                                                                        }
                                                                                                        </>
                                                                                                    }
                                                                                                    </>
                                                                                                }</>
                                                                                            }
                                                                                        </FormGroup>
                                                                                    </Col>
                                                                                    :
                                                                                    <> </>
                                                                                }
                                                                            </>
                                                                        )}
                                                                    </Row>
                                                                </div>
                                                            </Form>
                                                        </CardBody>
                                                        <CardFooter className="bg-white border-0">
                                                            <Row className="align-items-center">
                                                                <Col xs="8">
                                                                    {/* <h3 className="mb-0">{dynamicData.step.Name}</h3> */}
                                                                </Col>
                                                                <Col className="text-right" xs="4">
                                                                    <Button
                                                                        color="danger"
                                                                        onClick={() => { this.deleteInstance() }}
                                                                        size="sm"
                                                                    >
                                                                        Ακύρωση
                                                                    </Button>
                                                                    <Button
                                                                        color="success"
                                                                        onClick={() => { this.submitInstance(dynamicData.step.Status) }}
                                                                        size="sm"
                                                                        id="submit-button-2"
                                                                    >
                                                                        Υποβολή
                                                                    </Button>
                                                                </Col>
                                                            </Row>
                                                        </CardFooter>
                                                    </Card>
                                                </Col>
                                            </Row>
                                        </Container>
                                        : <Container className="mt-2" fluid>
                                            <Row className="mt-5">
                                                <Col className="order-xl-1" xl="12">
                                                    <Card className="bg-secondary shadow">
                                                        <CardBody>
                                                            <Form>
                                                                <div className="pl-lg-4">
                                                                    <Row>
                                                                        <Col lg="12">
                                                                            <p style={{ fontSize: '18px' }}>Θα ενημερωθείτε από τον Έλεγχο Πορείας για το επόμενο στάδιο της αίτησης που θα χρειαστεί να συμπληρώσετε.</p>
                                                                            <Button
                                                                                color="primary"
                                                                                href={"/consumer/instance/" + this.state.instance.Id}
                                                                                size="lg"
                                                                            >
                                                                                Έλεγχος πορείας
                                                                            </Button>
                                                                        </Col>
                                                                    </Row>
                                                                </div>
                                                            </Form>
                                                        </CardBody>
                                                    </Card>
                                                </Col>
                                            </Row>
                                        </Container>
                                    }
                                </>
                                :
                                <> </>
                            }
                        </>
                    )}
                </>
            );
        }
    }
}

export default InstanceDetails;
