import React from "react";
import ReactDOM from "react-dom";
import App from "./views/App.js";

import "assets/plugins/nucleo/css/nucleo.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "assets/scss/argon-dashboard-react.scss";
// import 'assets/css/rsuite-default.min.css';
import { config } from '@fortawesome/fontawesome-svg-core';

// add this line to set up FontAwesome
config.autoAddCss = false;

ReactDOM.render(<App />, document.getElementById("root"));