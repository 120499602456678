export const ValidatorHelper = {
    AFMValidator: {
        IsValidAfm(afmValue) {
            if (afmValue.length > 9) {
                return false;
            }
            if (Number.isInteger(parseInt(afmValue)) == false) {
                return false;
            }
            let afm = afmValue;//parseInt(afmValue);
            afm = afm.split('').reverse().join('');
            var Num1 = 0;
            for (var iDigit = 1; iDigit <= 8; iDigit++) { Num1 += afm.charAt(iDigit) << iDigit; }
            let value = (Num1 % 11) % 10 == afm.charAt(0);
            return value;
        },
        OnUpdateInputValueValidation(evt) {
            let length = (evt.target.value).length;
            if (length < 9) {
                return;
            }
            let value = evt.target.value;
            let isValidAfm = ValidatorHelper.AFMValidator.IsValidAfm(value);
            if (!isValidAfm) {
                if (length == 9) {
                    evt.target.value = value.substring(0, evt.target.value.length - 1);
                }
                else {
                    evt.target.value = '';
                }
            }
        }
    },
    // LengthValidator(length, value) : {
    //     return (/^\d{length}$/.test(value))
    // }
}

export default ValidatorHelper;