import React from 'react';
import { Input, Col } from 'reactstrap';
import ReactTooltip from 'react-tooltip';

const DefaultActionComponent = ({ dynamicData, dynamicActions, updateInputValue }) => {
    return (
        <React.Fragment>
            <i className="fa fa-info-circle" data-tip data-for={'info-form-control-alternative' + dynamicData.step.Id + dynamicActions.action.Id} aria-hidden="true"></i>
            <Col md={5}>
            <Input
                className="form-control-alternative"
                defaultValue={dynamicActions.data.Data}
                id={dynamicData.step.Id + "/" + dynamicActions.action.Id}
                placeholder={dynamicActions.action.Description}
                type={dynamicActions.action.Type}
                disabled={!dynamicActions.action.Required}
                onChange={evt => updateInputValue(evt)}
            />
            </Col>
            <ReactTooltip id={'info-form-control-alternative' + dynamicData.step.Id + dynamicActions.action.Id} type='info'>
                <span>{dynamicActions.action.Description}</span>
            </ReactTooltip>
        </React.Fragment>
    );
};

export default DefaultActionComponent;

