import {
  Input,
} from "reactstrap";
import { ValidatorHelper } from "./ValidatorHelper"
export const EVOControlsHelper = {
  ValidationHelper: {
    AFMValidator: {
      GetLengthMinMax: (limitType) => {
        let lengthMin = 0; //default values
        let lengthMax = 0;
        if (limitType) {

          if (limitType = 'afmlimit9') {
            lengthMin = 9;
            lengthMax = lengthMin;
          }
          if (limitType = 'aeriolimit14') {
            lengthMin = 8;
            lengthMax = 14;
          }
          if (limitType = 'limit9') {
            lengthMin = 8;
            lengthMax = 9;
          }

        }
        return {
          lengthMin: lengthMin,
          lengthMax: lengthMax
        }
      },
      IsValidAfm(afmValue) {
        return ValidatorHelper.AFMValidator.IsValidAfm(afmValue);
      }
    },
    IsPastedValueInLengthRange(e, min, max) {
      if (min === 0 && max == 0) {
        return true; //ignores case
      }
      let clipboardData = e.clipboardData || window.Clipboard;
      let pastedData = clipboardData.getData('Text');
      if (Number.isInteger(parseInt(pastedData)) == false) {
        return false;
      }
      let number = parseInt(pastedData);
      return EVOControlsHelper.ValidationHelper.isNumberLengthInRange(min, max, number)
    },
    IsPastedValueAFM(e) {
      let clipboardData = e.clipboardData || window.Clipboard;
      let pastedData = clipboardData.getData('Text');
      return EVOControlsHelper.ValidationHelper.AFMValidator.IsValidAfm(pastedData)
    },
    isNumberInRange(min, max, number) {
      if (number > max || number < min) {
        return false;
      }
      return true;
    },
    isNumberLengthInRange(min, max, number) {
      let value = String(number);
      return value.length >= min && value.length <= max;
    },
  },
  Input: ({ name, ...fields }) => {
    return (
      <>
        <Input
          name={name}
          {...fields}
        />

      </>
    )
  },
  InputInteger: ({ name, limitType, ...fields }) => {
    let lengthMinMax = EVOControlsHelper.ValidationHelper.AFMValidator.GetLengthMinMax(limitType);

    return (
      <>
        <Input
          name={name}
          {...fields}
          onKeyPress={(event) => {
            //allows only integers
            if (!/[0-9]/.test(event.key)) {
              event.preventDefault();
            }
          }}
          onPaste={(e) => {
            //let value = EVOControlsHelper.ValidationHelper.IsPastedValueInLengthRange(e, lengthMinMax.lengthMin, lengthMinMax.lengthMax);
            let value = EVOControlsHelper.ValidationHelper.IsPastedValueAFM(e);
            if (!value) { e.preventDefault(); }
          }}
        />

      </>
    )
  }
}
export default EVOControlsHelper;