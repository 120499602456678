import React from 'react';
import EVOControlsHelper from '../common/EVOControlsHelper'
import ReactTooltip from 'react-tooltip';

const NumberActionComponent = ({ dynamicData, dynamicActions, updateInputValueNumber }) => {
  return (
    <React.Fragment>
      <i className="fa fa-info-circle" data-tip data-for={'info-form-control-alternative' + dynamicData.step.Id + dynamicActions.action.Id} aria-hidden="true"></i>

      <EVOControlsHelper.InputInteger
        name={dynamicData.step.Id + "/" + dynamicActions.action.Id}
        limitType={dynamicActions.action.Target}
        className="form-control-alternative"
        defaultValue={dynamicActions.data.Data}
        id={dynamicData.step.Id + "/" + dynamicActions.action.Id}
        placeholder={dynamicActions.action.Description}
        type={dynamicActions.action.Type}
        disabled={!dynamicActions.action.Required}
        onChange={evt => updateInputValueNumber(evt, dynamicActions)}
      />
    </React.Fragment>
  );
};

export default NumberActionComponent;
