import React, { useState } from "react";
// node.js library that concatenates classes (strings)
import classnames from "classnames";

import { CommonLoading } from 'react-loadingg';


import axios from 'axios';

import {
  Button,
  Collapse,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  NavItem,
  NavLink,
  Nav,
  Progress,
  Table,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col
} from "reactstrap";

import InstanceHeader from "components/Headers/InstanceHeader.js";
import GenericHeader from "components/Headers/GenericHeader.js";
import Accordion from 'react-bootstrap/Accordion';
import Moment from "moment";
import 'moment/locale/el';

class ConsumerApplication extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            error: null,
            activeNav: 1,
            loading: true,
            application: [],
            enabled: true,
            name: '',
            lastname: '',
            email: '',
            phone: ''
        };
    }

    async componentDidMount() {
        const handle  = this.props.match.params.id;
        // console.log(handle);

        const result = await axios.get(process.env.REACT_APP_API_LINK + "/api/applications/check/" + handle + '/', {
            headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
        })
          .then(res => {
            this.setState({
                loading: false,
                application: res.data,
                name: res.data.Name,
                lastname: res.data.LastName,
                email: res.data.Email,
                phone: res.data.phoneNumber
            });
            // console.log(res);
          })
    };

    handleClick = stepStatus => {
        
        if (localStorage.getItem('token')) { 
            fetch(process.env.REACT_APP_API_LINK + '/api/applications/update/', {
                method: 'PUT',
                headers: new Headers({
                    'Authorization' : 'Bearer ' + localStorage.getItem('token'),
                }),
                body: JSON.stringify({
                    'Id' : this.state.application.Id,
                    'Name' : this.state.name,
                    'LastName' : this.state.lastname,
                    'Email' : this.state.email,
                    'phoneNumber' : this.state.phone
                })
            })
            .then(data => {
                // console.log(data);
                window.location.replace("/consumer/completeapplication/" + this.state.application.Id);
            })
        }
    }

    updateName(evt) {
        this.setState({
            name: evt.target.value
        });
    }
    updateLastName(evt) {
        this.setState({
            lastname: evt.target.value
        });
    }
    updatePhone(evt) {
        this.setState({
            phone: evt.target.value
        });
    }
    // updateEmail(evt) {
    //     this.setState({
    //         email: evt.target.value
    //     });
    // }

    render() {
        if (this.state.loading) {
            return (
                <>
                    <GenericHeader />
                    <CommonLoading />
                </>
            ) 
        }
        else {
            return (
            <>
                <InstanceHeader />
                <div className="header pb-4" style={{background: "linear-gradient(to right, #FFFFFF, #4A002B)"}}>
                    <Container fluid>
                        <div className="header-body">
                            <Row>
                                <Col lg="8" xl="12">
                                    <Card className="card-stats mb-4 mb-xl-0">
                                        <CardBody>
                                            <Row>
                                                <div className="col">
                                                    <CardTitle
                                                        tag="h5"
                                                        className="text-uppercase text-muted mb-0"
                                                    >
                                                    </CardTitle>
                                                        <span className="h2 font-weight-bold mb-0">
                                                        Φυσικό Αέριο
                                                        </span>
                                                    <CardBody>
                                                        <Row className="align-items-center"><span style={{fontWeight: "bold"}}>Διαθεσιμότητα Δικτύου: </span> {this.state.application.availability == 1 ? " Εντός Δικτύου" : " Εκτός Δικτύου"}</Row>
                                                        <Row className="align-items-center"><span style={{fontWeight: "bold"}}>Ημερομηνία Υποβολής: </span> {Moment(this.state.application.dateCreated).locale('el').format('dddd, DD MMM YYYY')}</Row>
                                                    </CardBody>
                                                </div>
                                            </Row>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                        </div>
                    </Container>
                </div>

                
                <Container className="mt-2" fluid>
                    <Row className="mt-5">
                        <Col className="order-xl-1" xl="12">
                            <Card className="bg-secondary shadow">
                                <CardHeader className="bg-white border-0">
                                        <Row className="align-items-center">
                                            <Col xs="8">
                                                Στοιχεία αίτησης στο deda.gr
                                            </Col>
                                            <Col className="text-right" xs="4">
                                                {this.state.application.completed 
                                                ?   <p></p> : 
                                                    <Button
                                                    color="primary"
                                                    onClick={() => { this.handleClick() }}
                                                    size="sm"
                                                    >
                                                    Επόμενο βήμα
                                                    </Button>
                                                }
                                            </Col>
                                        </Row>
                                </CardHeader>
                                    <CardBody>
                                    <p>Επιβεβαιώστε τα στοιχεία σας και σε περίπτωση σφάλματος, επιστρέψτε στην προηγούμενη οθόνη, διαγράψετε την εκδήλώση ενδιαφέροντος και επαναλάβετε από την αρχή την διαδικασία.</p>
                                        <Form>
                                            <div className="pl-lg-4">
                                                <h6 className="heading-small text-muted mb-4">
                                                    Στοιχεια Καταναλωτη
                                                </h6>

                                                <Row>
                                                    <Col lg="3">
                                                        <FormGroup>
                                                            <label
                                                                className="form-control-label"
                                                                htmlFor="input-username"
                                                            >
                                                                Όνομα
                                                            </label>
                                                            <Input
                                                                className="form-control-alternative"
                                                                defaultValue={this.state.application.Name}
                                                                id="input-username"
                                                                placeholder="Όνομα"
                                                                type="text"
                                                                disabled='true'
                                                                onChange={evt => this.updateName(evt)}
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                    <Col lg="3">
                                                        <FormGroup>
                                                            <label
                                                                className="form-control-label"
                                                                htmlFor="input-username"
                                                            >
                                                                Επίθετο
                                                            </label>
                                                            <Input
                                                                className="form-control-alternative"
                                                                defaultValue={this.state.application.LastName}
                                                                id="input-username"
                                                                placeholder="Επίθετο"
                                                                type="text"
                                                                disabled='true'
                                                                onChange={evt => this.updateLastName(evt)}
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                    <Col lg="3">
                                                        <FormGroup>
                                                            <label
                                                                className="form-control-label"
                                                                htmlFor="input-username"
                                                            >
                                                                Τηλέφωνο Επικοινωνίας
                                                            </label>
                                                            <Input
                                                                className="form-control-alternative"
                                                                defaultValue={this.state.application.phoneNumber}
                                                                id="input-username"
                                                                placeholder="Τηλέφωνο Επικοινωνίας"
                                                                type="text"
                                                                disabled='true'
                                                                onChange={evt => this.updatePhone(evt)}
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                    <Col lg="3">
                                                        <FormGroup>
                                                            <label
                                                                className="form-control-label"
                                                                htmlFor="input-username"
                                                            >
                                                                Email
                                                            </label>
                                                            <Input
                                                                className="form-control-alternative"
                                                                defaultValue={this.state.application.Email}
                                                                id="input-username"
                                                                placeholder="Email"
                                                                type="text"
                                                                disabled='true'
                                                                // onChange={evt => this.updateEmail(evt)}
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                </Row>

                                                <h6 className="heading-small text-muted mb-4">
                                                    Στοιχεια Σημειου Εγκαταστασης
                                                </h6>
                                                <div className="pl-lg-4"></div>
                                                <Row>
                                                    <Col lg="3">
                                                        <FormGroup>
                                                            <label
                                                                className="form-control-label"
                                                                htmlFor="input-username"
                                                            >
                                                                Διεύθυνση
                                                            </label>
                                                            <Input
                                                                className="form-control-alternative"
                                                                defaultValue={this.state.application.mainAddress}
                                                                id="input-username"
                                                                placeholder="Διεύθυνση"
                                                                type="text"
                                                                disabled='true'
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                    <Col lg="3">
                                                        <FormGroup>
                                                            <label
                                                                className="form-control-label"
                                                                htmlFor="input-username"
                                                            >
                                                                Αριθμός
                                                            </label>
                                                            <Input
                                                                className="form-control-alternative"
                                                                defaultValue={this.state.application.addressNum}
                                                                id="input-username"
                                                                placeholder="Αριθμός"
                                                                type="text"
                                                                disabled='true'
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                    <Col lg="3">
                                                        <FormGroup>
                                                            <label
                                                                className="form-control-label"
                                                                htmlFor="input-username"
                                                            >
                                                                Πόλη
                                                            </label>
                                                            <Input
                                                                className="form-control-alternative"
                                                                defaultValue={this.state.application.city}
                                                                id="input-username"
                                                                placeholder="Πόλη"
                                                                type="text"
                                                                disabled='true'
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                    <Col lg="3">
                                                        <FormGroup>
                                                            <label
                                                                className="form-control-label"
                                                                htmlFor="input-username"
                                                            >
                                                                Περιφέρεια
                                                            </label>
                                                            <Input
                                                                className="form-control-alternative"
                                                                defaultValue={this.state.application.state}
                                                                id="input-username"
                                                                placeholder="Περιφέρεια"
                                                                type="text"
                                                                disabled='true'
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col lg="3">
                                                        <FormGroup>
                                                            <label
                                                                className="form-control-label"
                                                                htmlFor="input-username"
                                                            >
                                                                Ταχυδρομικός Κώδικας
                                                            </label>
                                                            <Input
                                                                className="form-control-alternative"
                                                                defaultValue={this.state.application.zipCode}
                                                                id="input-username"
                                                                placeholder="Διεύθυνση"
                                                                type="text"
                                                                disabled='true'
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                    <Col lg="3">
                                                        <FormGroup>
                                                            <label
                                                                className="form-control-label"
                                                                htmlFor="input-username"
                                                            >
                                                                Γεωγραφικό πλάτος
                                                            </label>
                                                            <Input
                                                                className="form-control-alternative"
                                                                defaultValue={this.state.application.lat}
                                                                id="input-username"
                                                                placeholder="Αριθμός"
                                                                type="text"
                                                                disabled='true'
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                    <Col lg="3">
                                                        <FormGroup>
                                                            <label
                                                                className="form-control-label"
                                                                htmlFor="input-username"
                                                            >
                                                                Γεωγραφικό μήκος
                                                            </label>
                                                            <Input
                                                                className="form-control-alternative"
                                                                defaultValue={this.state.application.lon}
                                                                id="input-username"
                                                                placeholder="Πόλη"
                                                                type="text"
                                                                disabled='true'
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                            </div>
                                        </Form>
                                    </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </>
            );
        }
    }
}

export default ConsumerApplication;
