import React from "react";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col
} from "reactstrap";
// core components
import GenericHeader from "components/Headers/GenericHeader.js";

class IndexSignUp extends React.Component {
    constructor(props){
        super(props);
        this.state = {
          error: null,
          activeNav: 1,
          isLoaded: false,
          user: [],
          roles: []
        };
      }
    
      componentDidMount() {
      }

  render() {
    return (
      <>
        <GenericHeader />
        {/* Page content */}
        <Container className="mt--8" fluid>
          <Row className=""> 
            <Col className="" xl="12">
              <Card className="bg-secondary shadow">
                <CardHeader className="bg-white border-0">
                  <Row className="align-items-center">
                    <Col xs="8">
                      <h3 className="mb-0">Νέος χρήστης</h3>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody>
                    <div className="pl-lg-4" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '30vh'}}>
                        <Row>
                            <Button
                            style={{textAlign: "center", width: "30vh", height: "10vh", lineHeight: "55px"}}
                            color="primary"
                            href="/signup/consumer"
                            >
                                Ιδιώτης
                            </Button>
                            <Button
                            style={{textAlign: "center", width: "40vh", height: "10vh", lineHeight: "55px"}}
                            color="success"
                            href="/signup/consumerrep"
                            >
                                Εξουσιοδοτημένος εκπρόσωπος
                            </Button>
                        </Row>
                    </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
          </Container>
        
          <Container className="mt-3" fluid>
          <Row className=""> 
            <Col xl="12">
              <Card className="bg-secondary shadow">
              <CardHeader className="bg-white border-0">
                  <Row className="align-items-center">
                    <Col xs="8">
                      <h3 className="mb-0">Υπάρχον χρήστης</h3>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody>
                    <div className="pl-lg-4" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '30vh'}}>
                        <Row>
                            
                            <Button
                            style={{textAlign: "center", width: "40vh", height: "10vh", lineHeight: "55px"}}
                            color="primary"
                            href="/signup/consumerrep"
                            >
                                Είστε ήδη χρήστης ; Συνδεθείτε εδώ
                            </Button>
                        </Row>
                    </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

export default IndexSignUp;
