import React from "react";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
  Table
} from "reactstrap";
// core components

import GenericHeader from "components/Headers/GenericHeader.js";
import InstanceHeader from "components/Headers/InstanceHeader.js";

import { CommonLoading } from 'react-loadingg';

import Moment from "moment";
import 'moment/locale/el';

import axios from 'axios';

class InstanceProfile extends React.Component {
    constructor(props){
        super(props);
        this.state = {
          error: null,
          loading: true,
          details: [],
          files: [],
          user: [],
          dates: []
        };
      }
    
      async componentDidMount() {
        const handle  = this.props.match.params.id;
        // console.log(handle);
    
        if (localStorage.getItem('token')) {
          fetch(process.env.REACT_APP_API_LINK + "/api/workflowinstances/details/" + handle +'/', {
            method: 'get',
            headers: new Headers({
              'Authorization' : 'Bearer ' + localStorage.getItem('token'),
              'Content-Type': 'application/x-www-form-urlencoded'
            })
          })
          .then(res => res.json())
          .then(
            (result) => {
              this.setState({
                details: result,
              });
            },
            (error) => {
              this.setState({
                error
              });
            }
          )

          fetch(process.env.REACT_APP_API_LINK + "/api/files/instance/" + handle + '/', {
            method: 'get',
            headers: new Headers({
              'Authorization' : 'Bearer ' + localStorage.getItem('token'),
              'Content-Type': 'application/x-www-form-urlencoded'
            })
          })
          .then(res => res.json())
          .then(
            (result) => {
              this.setState({
                files: result,
              });
            },
            (error) => {
              this.setState({
                error
              });
            }
          )

          await fetch(process.env.REACT_APP_API_LINK + "/api/auth/user/", {
            method: 'get',
            headers: new Headers({
                'Authorization' : 'Bearer ' + localStorage.getItem('token'),
                'Content-Type': 'application/x-www-form-urlencoded'
            })
          })
          .then(res => res.json())
          .then(
            (result) => {
              // console.log(result);
              this.setState({
              isLoaded: true,
              user: result,
              });
            },
            (error) => {
              // console.log(error);
                this.setState({
                isLoaded: true,
                error
                });
            }
          )
        };
      }    

    //   recallStep = status => {
    //     if (localStorage.getItem('token')) { 
    //      fetch(process.env.REACT_APP_API_LINK + '/api/workflowinstances/recall/' + this.state.details.Id + '/', {
    //           method: 'POST',
    //           headers: new Headers({
    //               'Authorization' : 'Bearer ' + localStorage.getItem('token'),
    //           })
    //       })
    //       .then(data => {
    //         // console.log(data);
    //         window.location.reload(false);
    //       })
    //     }
    //   }

  render() {
    if (this.state.loading) {
      return (
          <>
              <GenericHeader />
              <CommonLoading />
          </>
      ) 
    }
    else {
      return (
        <>
          <GenericHeader />
          {/* Page content */}
          <Container className="mt--7" fluid>
            <Row>
              <Col className="order-xl-1" xl="12">
                <Card className="bg-secondary shadow">
                  <CardHeader className="bg-white border-0">
                    <Row className="align-items-center">
                      <Col xs="8">
                        <h3 className="mb-0">Στοιχεία Αίτησης</h3>
                      </Col>
                      <Col className="text-right" xs="4">
                        <Button
                          href="/admin/instancesettings"
                          // style={{ backgroundColor: this.state.yellow }}
                          // color={this.state.yellow}
                          color='primary'
                          size="sm"
                        >
                          Ρυθμίσεις
                        </Button>
                      </Col>
                    </Row>
                  </CardHeader>
                  <CardBody>
                    <Form>
                      <h6 className="heading-small text-muted mb-4">
                        Βασικες πληροφοριες
                      </h6>
                      <div className="pl-lg-4">
                        <Row>
                          <Col lg="1">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-username"
                              >
                                Α/Α
                              </label>
                              <Input
                                className="form-control-alternative"
                                defaultValue={this.state.details.SerialNumber}
                                id="input-username"
                                placeholder="Α/Α"
                                type="text"
                                // disabled={true}
                                // onChange={evt => this.updateName(evt)}
                              />
                            </FormGroup>
                          </Col>
                          <Col lg="3">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-email"
                              >
                                Ημερομηνία Έναρξης
                              </label>
                              <Input
                                className="form-control-alternative"
                                defaultValue={Moment(this.state.details.StartedAt).locale('el').format('dddd, DD MMM YYYY')}
                                id="input-email"
                                placeholder="Ημερομηνία Έναρξης"
                                type="text"
                                // disabled={true}
                                // onChange={evt => this.updateLastName(evt)}
                              />
                            </FormGroup>
                          </Col>
                          <Col lg="3">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-email"
                              >
                                Κατηγορία Αιτήματος
                              </label>
                              <Input
                                className="form-control-alternative"
                                id="input-email"
                                defaultValue={this.state.ikasp.Ikasp}
                                placeholder="Αριθμός ΗΚΑΣΠ"
                                type="text"
                                // disabled={true}
                                // onChange={evt => this.updateEmail(evt)}
                              />
                            </FormGroup>
                          </Col>
                          <Col lg="3">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-email"
                              >
                                Κατάσταση Αιτήματος
                              </label>
                              {this.state.details.Status == 1 ?
                                <Input
                                  className="form-control-alternative"
                                  id="input-email"
                                  defaultValue='1. Υποβολή Αιτήματος'
                                  placeholder="Κατάσταση Αίτησης"
                                  type="text"
                                /> : <> </> }
                              {this.state.details.Status == 2 ? 
                                <Input
                                  className="form-control-alternative"
                                  id="input-email"
                                  defaultValue='2. Επεξεργασία Αιτήματος'
                                  placeholder="Κατάσταση Αίτησης"
                                  type="text"
                                /> : <> </> }
                              {this.state.details.Status == 3 ? 
                                <Input
                                  className="form-control-alternative"
                                  id="input-email"
                                  defaultValue='3. Αίτημα προς Ολοκλήρωση'
                                  placeholder="Κατάσταση Αίτησης"
                                  type="text"
                                /> : <> </> }
                              {this.state.details.Status == 4 ? 
                                <Input
                                  className="form-control-alternative"
                                  id="input-email"
                                  defaultValue='4. Διευκρίνιση σε περίπτωση απόρριψης'
                                  placeholder="Κατάσταση Αίτησης"
                                  type="text"
                                /> : <> </> }
                            </FormGroup>
                          </Col>
                        </Row>
                      </div>

                      <hr className="my-4" />
                      <div className="pl-lg-4">
                        <Row>
                          <Col md="6" >
                            <h6 className="heading-small text-muted mb-4">
                                Επιλογες Αιτηματος
                            </h6>
                            <Button
                              color="primary"
                              href={"/admin/instance/" + this.state.details.Id}
                              size="sm"
                            >
                              Βήματα αιτήματος
                            </Button>
                            {/* {this.state.user.PersonRole == 'Admin' || this.state.user.PersonRole == 'Sales' ? 
                              <>
                                <Button
                                  color="danger"
                                  onClick={() => { this.recallStep() }}
                                  size="sm"
                                >
                                  Ανάκληση πρόσφατου βήματος
                                </Button>
                              </> : <> </>} */}
                          </Col>

                          <Col md="6">
                            <h6 className="heading-small text-muted mb-4">
                                Σχόλια/Παρατηρήσεις Αιτήματος
                            </h6>
                            <Input
                                className="form-control-alternative"
                                id="input-email"
                                defaultValue={this.state.details.Comments}
                                placeholder="Σχόλια/Παρατηρήσεις Αιτήματος"
                                type="textarea"
                                disabled={true}
                              />
                          </Col>
                        </Row>
                      </div>

                      <hr className="my-4" />
                      <div className="pl-lg-4">
                        <Row>
                          <Col md="12" >  
                            <h6 className="heading-small text-muted mb-4">
                                Στοιχεια συμβεβλημενων προσωπων
                            </h6>
                            {this.state.user.PersonRole == 'Admin' || this.state.user.PersonRole == 'Guest' || this.state.user.PersonRole == 'Sales' || this.state.user.PersonRole == 'Marketing' || this.state.user.PersonRole == 'Maintenance' || this.state.user.PersonRole == 'Mechanic' || this.state.user.PersonRole == 'Finance' ? 
                              <>
                                <Button
                                  color="primary"
                                  href={"/admin/consumer/" + this.state.details.UserId}
                                  size="sm"
                                >
                                  Στοιχεία Καταναλωτή
                                </Button>
                              </> : <> </>}
                          </Col>
                        </Row>
                      </div>
                    </Form>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            
            {this.state.user.PersonRole == 'Admin' || this.state.user.PersonRole == 'Guest' || this.state.user.PersonRole == 'Sales' || this.state.user.PersonRole == 'Marketing'? 
              <>
                <Row className="mt-5">
                  <Col className="mb-5 mb-xl-0" xl="6">
                    <Card className="bg-secondary shadow">
                      <CardHeader className="border-0">
                        <Row className="align-items-center">
                          <Col xs="8">
                              <h3 className="mb-0">Έγγραφα αιτήματος</h3>
                          </Col>
                        </Row>
                        
                      </CardHeader>
                      <Table className="align-items-center table-flush" responsive>
                        <thead className="thead-light">
                          <tr>
                              <th scope="col">Ονομα αρχειου</th>
                              <th scope="col">Ημερομηνια Αναρτησης</th>
                              <th scope="col">Ενεργειες</th>
                          </tr>
                        </thead>
                        <tbody>
                        {
                          this.state.files.map((dynamicFiles) => 
                            <tr>
                                <td>{dynamicFiles.FileName}</td>
                                <td>{Moment(dynamicFiles.UploadDate).locale('el').format('dddd, DD MMM YYYY')}</td>
                                <td>
                                  <Button
                                    color="primary"
                                    href={dynamicFiles.Link}
                                    size="sm"
                                    title="Προβολή"
                                  >
                                    <i className="fas fa-eye" />
                                  </Button>
                                  {this.state.user.PersonRole == 'Admin' ? 
                                    <>
                                      <Button
                                        color="danger"
                                        // href={dynamicFiles.Link}
                                        size="sm"
                                        title="Διαγραφή"
                                      >
                                        <i className="fas fa-trash" />
                                      </Button>
                                    </> : <> </> } 
                                </td>
                            </tr> 
                          ) 
                        }
                        </tbody>
                      </Table>
                    </Card>
                  </Col>

                  {/* <Col className="mb-5 mb-xl-0" xl="6">
                        <Card className="bg-secondary shadow">
                            <CardHeader className="border-0">
                                <Row className="align-items-center">
                                    <Col xs="8">
                                        <h3 className="mb-0">Στίγμα αίτησης</h3>
                                    </Col>
                                </Row>
                            </CardHeader>
                            <CardBody>
                              <MapContainer center={[40.891360000000084, 22.914040000000057]} zoom={13} scrollWheelZoom={false} style={{ height: '23vh', width: '50wh' }}>
                                <TileLayer
                                  attribution='&copy; EvolutionProjects+'
                                  url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                />
                                <Marker position={[40.891360000000084, 22.914040000000057]} icon={customMarker}>
                                  <Popup>
                                    A/A : {this.state.details.SerialNumber} <br /> HKAΣΠ : {this.state.ikasp.Ikasp}
                                  </Popup>
                                </Marker>
                              </MapContainer>
                            </CardBody>
                        </Card>
                    </Col> */}
                </Row>
              </> : <> </>}
          </Container>
        </>
      );
    }
  }
}

export default InstanceProfile;
