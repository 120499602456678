import React from "react";
// node.js library that concatenates classes (strings)
import classnames from "classnames";

import axios from 'axios';

import {
    Button,
    Card,
    CardHeader,
    CardBody,
    NavItem,
    NavLink,
    Nav,
    Progress,
    Table,
    Container,
    Row,
    Col
} from "reactstrap";

import GenericHeader from "components/Headers/GenericHeader.js";
// import Spinner from "views/examples/Spinner.js";
import Moment from "moment";
import { useState } from "react";
import 'moment/locale/el';

import { MDBDataTable } from 'mdbreact';

import '../../../assets/css/datatable.css';

import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";

import ReactHTMLTableToExcel from 'react-html-table-to-excel';


const chbx = [
    { id: 13, text: "Νέα " },
    { id: 110, text: "Ανακοινώσεις " },
    { id: 28, text: "Αποφάσεις " },
    { id: 109, text: "Διαβουλεύσεις " },
];

class oldPosts extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            error: null,
            RAEpostsTitle: [],
            RAEpostsUrl: [],
            RAEpostsContent: [],
            posts: [],
            dummy: [],
            selectedCheckboxes: [],
            selectedCheckboxesOldPosts: [13, 110, 28, 109],
            checkboxes: [],
            startDate: new Date(),
            otherDay: new Date(),
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleChange2 = this.handleChange2.bind(this);
        this.state.startDate.setMonth(this.state.otherDay.getMonth() - 2);

    }

    diffDays(date, otherDate) {
        let difference = Math.ceil(Math.abs(date - otherDate) / (1000 * 60 * 60 * 24));

        if (difference < 63 && difference > 0)
            return true;
        else
            return false;
    }

    handleChange(date) {
        this.setState({
            startDate: date
        });
        // this.state.otherDay.setDate(date.getDate());
        // this.state.otherDay.setMonth(date.getMonth() + 3);

        // // //console.log(parseInt(this.state.otherDay.getFullYear()) - parseInt(date.getFullYear()));
        // // // //console.log(date.getFullYear());
        // this.state.otherDay.setFullYear(2022);
        // // // //console.log(date);

        if (this.diffDays(date, this.state.otherDay)) {
            document.getElementById('datepicker_error').style.display = 'none';
            document.getElementById('datatableContainer').style.display = 'block';

            this.getDates_call(date, this.state.otherDay);

            this.fetchRAEwordpressdata(date, this.state.otherDay);

            // this.test(date, this.state.otherDay);

        }

        else {
            document.getElementById('datepicker_error').style.display = 'block';
            document.getElementById('datatableContainer').style.display = 'none';

            document.getElementById('datepicker_error').innerHTML = 'Παρακαλώ επιλέξτε εύρος εντός 2 μηνών';

        }

    }

    handleChange2(date) {
        this.setState({
            otherDay: date
        })


        if (this.diffDays(this.state.startDate, date)) {
            document.getElementById('datepicker_error').style.display = 'none';
            document.getElementById('datatableContainer').style.display = 'block';

            this.getDates_call(this.state.startDate, date);

            this.fetchRAEwordpressdata(this.state.startDate, date);

        }
        else {
            document.getElementById('datepicker_error').style.display = 'block';
            document.getElementById('datatableContainer').style.display = 'none';
            document.getElementById('datepicker_error').innerHTML = 'Παρακαλώ επιλέξτε εύρος εντός 2 μηνών';

            // this.getDates_call(this.state.startDate, this.state.otherDay);
        }

    }

    getDates(startDate, endDate) {
        var dates = [],
            currentDate = startDate,
            addDays = function (days) {
                var date = new Date(this.valueOf());
                date.setDate(date.getDate() + days);
                return date;
            };
        while (currentDate <= endDate) {

            dates.push(currentDate.toLocaleDateString('en-GB'));
            currentDate = addDays.call(currentDate, 1);
        }
        this.state.array = dates;
        return dates;
    };

    getDates_call(date, otherDate) {
        var dates = this.getDates(date, otherDate);
    }

    async componentDidMount() {
        // this.fetchRAEposts();

        if (localStorage.getItem('token')) {
            await fetch(process.env.REACT_APP_API_LINK + "/api/auth/user/", {
                method: 'get',
                headers: new Headers({
                    'Authorization': 'Bearer ' + localStorage.getItem('token'),
                    'Content-Type': 'application/x-www-form-urlencoded'
                })
            })
                .then(res => res.json())
                .then((result) => {
                    //console.log(result);


                    this.setState({
                        userDetails: result
                    });

                    // //console.log(document.getElementById("saveButton"));
                    


                })

            this.fetchRAEwordpressdata(this.state.startDate, this.state.otherDay);

            //console.log(this.state.userDetails.Id);
        }

        // this.fetchRAEposts();

        var self = this;

        await fetch(process.env.REACT_APP_API_LINK + "/api/mailinglist/categories/" + this.state.userDetails.Id + "/", {
            method: 'get',
            headers: new Headers({
                'Authorization': 'Bearer ' + localStorage.getItem('token'),
                'Content-Type': 'application/x-www-form-urlencoded'
            })
        })
            .then(res => res.json())
            .then((result) => {
                for (const id of result) {
                    self.state.selectedCheckboxes.push(parseInt(id));
                }

                this.setState({
                    checkboxes: chbx
                })
            })



    }

    onChange = id => {

        const selectedCheckboxes = this.state.selectedCheckboxes;

        // //console.log(id);
        // Find index
        const findIdx = selectedCheckboxes.indexOf(id);

        // Index > -1 means that the item exists and that the checkbox is checked
        // and in that case we want to remove it from the array and uncheck it

        if (findIdx > -1) {
            selectedCheckboxes.splice(findIdx, 1);
        } else {
            if (!selectedCheckboxes.includes(id)) {
                selectedCheckboxes.push(id);
            }

        }

        this.setState({
            selectedCheckboxes: selectedCheckboxes
        });

        //console.log(this.state)
    };

    onChangeOldPosts = id => {

        const selectedCheckboxes = this.state.selectedCheckboxesOldPosts;

        // //console.log(id);
        // Find index
        const findIdx = selectedCheckboxes.indexOf(id);

        // Index > -1 means that the item exists and that the checkbox is checked
        // and in that case we want to remove it from the array and uncheck it

        if (findIdx > -1) {
            selectedCheckboxes.splice(findIdx, 1);
        } else {
            if (!selectedCheckboxes.includes(id)) {
                selectedCheckboxes.push(id);
            }

        }
        this.state.selectedCheckboxesOldPosts = selectedCheckboxes;
        this.fetchRAEwordpressdata(this.state.startDate, this.state.otherDay);
        

        //console.log(this.state)
    };

    checkFacebookOrGoogle = () => {
        if (this.state.userDetails.Email.includes("@facebook.com") || this.state.userDetails.Email.includes("@google.com"))
            return true;
        return false;
    }

    fetchRAEwordpressdata = (from = '', to = '') => {
        //console.log(from.toLocaleDateString('en-GB'));
        //console.log(to.toLocaleDateString('en-GB'));

        var fromArray = from.toLocaleDateString('en-GB').split('/');
        var toArray = to.toLocaleDateString('en-GB').split('/');

        var correctFromDate = fromArray[2] + '-' + fromArray[1] + '-' + fromArray[0] + 'T00:00:00';
        var correctToDate = toArray[2] + '-' + toArray[1] + '-' + toArray[0] + 'T00:00:00';

        // from.toLocaleDateString('en-GB').spl

        if (localStorage.getItem('token')) {
            fetch("https://rae.gr/wp-json/wp/v2/posts?orderby=date&order=desc&before=" + correctToDate + "&after=" + correctFromDate + "&categories=" + this.state.selectedCheckboxesOldPosts + "&_fields[]=id&_fields[]=title&_fields[]=categories&_fields[]=date&_fields[]=link&per_page=500", {
                method: 'GET',
            })
                .then(res => res.json())
                .then(data => {
                    //console.log(data);
                    try
                    {
                        data.forEach(function (part, index) {
                            this[index].title = <a href={this[index].link} title={this[index].title.rendered}>{this[index].title.rendered.substring(0, 50) + '...'}</a>;;
                            this[index].date = this[index].date.split('T')[0];
                            this[index].categories = this[index].categories[0] == 13 ? "Νέα" : this[index].categories[0] == 110 ? "Ανακοινώσεις" : this[index].categories[0] == 28 ? "Αποφάσεις" : this[index].categories[0] == 109 ? "Διαβουλεύσεις" : "";
                            this[index].link = <a href={this[index].link}>{this[index].link}</a>
                        }, data);

                        this.setState({
                            dummy: {
                                columns: [
                                    {
                                        label: 'Τιτλος ▼',
                                        field: 'title',
                                        sort: 'asc',
                                        width: 100
                                    },
                                    {
                                        label: 'Ημερομηνια ▼',
                                        field: 'date',
                                        sort: 'asc',
                                        width: 150
                                    },
                                    {
                                        label: 'Κατηγορια ▼',
                                        field: 'categories',
                                        sort: 'asc',
                                        width: 150
                                    },
                                    {
                                        label: 'Συνδεσμος ▼',
                                        field: 'link',
                                        sort: 'asc',
                                        width: 150
                                    },
    
                                ],
                                rows: data,
                            }
                        })
                    }
                    catch
                    {
                        //console.log('no category selected!');
                    }
                   

                    


                })
        }
    }

    render() {
        return (
            <>
                <GenericHeader />
                <Container className="mt--8" fluid>
                    <Row className="mt-5">
                        <Col className="mb-5 mb-xl-0" xl="12">
                            <Card className="shadow">

                                <CardHeader className="border-0">
                                    <h3 style={{ paddingBottom: '25px' }} className="mb-0">Αναζήτηση παλαιότερων δημοσιεύσεων </h3>
                                    <Row className="align-items-center">

                                        <div className="container-fluid">
                                            <div className="row">
                                                <div className="col-sm">
                                                    Από
                                                    <DatePicker
                                                        selected={this.state.startDate}
                                                        onChange={this.handleChange}
                                                        name="startDate"
                                                        dateFormat="dd/MM/yyyy"
                                                    />
                                                </div>
                                                {this.getDates_call()}
                                                <div className="col-sm">
                                                    Έως
                                                    <DatePicker
                                                        selected={this.state.otherDay}
                                                        onChange={this.handleChange2}
                                                        name="startDate"
                                                        dateFormat="dd/MM/yyyy"
                                                    />
                                                </div>
                                                <div className="col-sm">
                                                    {/* One of three columns */}
                                                </div>
                                                <div className="col-sm">
                                                    {/* One of three columns */}
                                                </div>
                                                <div className="col-sm">
                                                    {/* One of three columns */}
                                                </div>
                                                <div className="col-sm">
                                                    {/* One of three columns */}
                                                </div>
                                                <div className="col-sm">
                                                    {/* One of three columns */}
                                                </div>

                                            </div>
                                        </div>
                                        <span style={{ color: 'red' }} id="datepicker_error"></span>





                                        <div className="container-fluid" id="datatableContainer" style={{ display: 'block' }}>
                                            <div className="row">


                                                <div className="col" style={{ marginTop: '30px' }}>

                                                    {this.state.checkboxes.map(checkbox => (
                                                        <label key={checkbox.id}>
                                                            <input
                                                                type="checkbox"
                                                                defaultChecked={this.state.selectedCheckboxesOldPosts.includes(checkbox.id)}
                                                                onChange={() => this.onChangeOldPosts(checkbox.id)}
                                                                defaultChecked={true}
                                                                style={{marginLeft:'10px'}}
                                                            /> &nbsp;
                                                            {checkbox.text}
                                                        </label>
                                                    ))}

                                                    <MDBDataTable
                                                        bordered
                                                        exportToCSV
                                                        hover
                                                        responsive
                                                        strip
                                                        data={this.state.dummy}
                                                        id="mdbdttable"
                                                        entriesOptions={[10, 25, 50, 100, 500, 1000]} entries={10}
                                                        noDataComponent="Δεν υπάρχουν δεδομένα"
                                                        searchLabel="Αναζήτηση"
                                                        paginationLabel={['Προηγούμενο', 'Επόμενο']}
                                                        infoLabel={['Εμφανίζονται', 'έως', 'από', 'καταχωρήσεις']}
                                                        entriesLabel={'Εμφάνιση Αιτημάτων ανά: '}
                                                    />
                                                </div>

                                            </div>
                                        </div>



                                    </Row>
                                    <div className="row" >
                                        <div className="col">
                                            <div className="col-md-12">

                                            </div>
                                            <ReactHTMLTableToExcel
                                                id="test-table-xls-button"
                                                className="btn btn-success btn-sm"
                                                table="mdbdttable"
                                                filename={"Δημοσιεύσεις από " + this.state.startDate.toLocaleDateString('en-GB') + " έως " + this.state.otherDay.toLocaleDateString('en-GB')}
                                                sheet="Δημοσιέυσεις"
                                                buttonText="Εξαγωγή σε EXCEL" />
                                            <span>&nbsp;   Το αρχείο .xls θα περιέχει μόνο όσες καταχωρήσεις έχετε επιλέξει να εμφανίζονται στην τρέχουσα σελίδα. </span>
                                        </div>
                                    </div>
                                </CardHeader>

                            </Card>
                        </Col>
                    </Row>
                </Container>

            </>
        );
    }
}

export default oldPosts;