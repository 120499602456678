import React from "react";

// reactstrap components
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    FormGroup,
    Form,
    Input,
    Container,
    Row,
    Col
} from "reactstrap";
// core components
import GenericHeader from "components/Headers/GenericHeader.js";

import axios from 'axios';

class ConsumerSettings extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            error: null,
            activeNav: 1,
            isLoaded: false,
            user: [],
            roles: [],
            pass1: "",
            pass2: "",
            changedPass: false,
            success: 0,
            errormsg: 0,            
            fullfilledPassword: false,
            fullfilledPasswordConfirmation: false,
        };

        // this.pass1 = this.getPass1.bind(this);
        // this.pass2 = this.getPass2.bind(this);

        this.getPass1 = this.getPass1.bind(this);
        this.getPass2 = this.getPass2.bind(this);
        // this.changePassword = this.changePassword.bind(this);
    }

    componentDidMount() {
        document.getElementById('submit-button-register').setAttribute('disabled', '');
        if (localStorage.getItem('token')) {
            fetch(process.env.REACT_APP_API_LINK + "/api/auth/user/", {
                method: 'get',
                headers: new Headers({
                    'Authorization': 'Bearer ' + localStorage.getItem('token'),
                    'Content-Type': 'application/x-www-form-urlencoded'
                })
            })
                .then(res => res.json())
                .then(
                    (result) => {
                        this.setState({
                            isLoaded: true,
                            user: result,
                        });
                    },
                    (error) => {
                        this.setState({
                            isLoaded: true,
                            error
                        });
                    }
                )
        };

        if (localStorage.getItem('token')) {
            fetch(process.env.REACT_APP_API_LINK + "/api/user/roles/", {
                method: 'get',
                headers: new Headers({
                    'Authorization': 'Bearer ' + localStorage.getItem('token'),
                    'Content-Type': 'application/x-www-form-urlencoded'
                })
            })
                .then(res => res.json())
                .then(
                    (result) => {
                        this.setState({
                            roles: result
                        });
                    },
                    (error) => {
                        this.setState({
                            isLoaded: true,
                            error
                        });
                    }
                )
        };
    }

    changePassword = consumerId => {
        if (localStorage.getItem('token')) {
            fetch(process.env.REACT_APP_API_LINK + '/api/auth/changepassword/', {
                method: 'POST',
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('token'),
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    'password' : this.state.password1
                })

            })
                .then(res => {
                    window.location.replace('/consumer/index');
                    console.log(res.data);
                    this.setState({
                        changePassword: true,
                        // success: res.data.success
                    })
                })
        }
    }

    getPass11(evt) {
        console.log(this.state.pass1);
        this.setState({
            pass1: evt.target.value
        });
        console.log(this.state.pass1);
    }

    getPass22(evt) {
        console.log(this.state.pass2);
        this.setState({
            pass2: evt.target.value
        });
        console.log(this.state.pass2);
    }
    
    getPass1(event) {
        document.getElementById("message").style.display = "block";

        let letter_complete = false;
        let capital_complete = false;
        let number_complete = false;
        let length_complete = false;


        var letter = document.getElementById("letter");
        var capital = document.getElementById("capital");
        var number = document.getElementById("number");
        var length = document.getElementById("length");

        if (event.target.value.trim() == '') {
            document.getElementById("message").style.display = "none";
            let input_password_validation_error = document.getElementById('input-password-validation-error');
            input_password_validation_error.innerHTML = 'Το πεδίο είναι απαραίτητο';
            input_password_validation_error.style.display = "block";
            this.setState({ fullfilledPassword: false });
            document.getElementById('submit-button-register').setAttribute('disabled', '');
        }

        else {
            document.getElementById("message").style.display = "block";
            var lowerCaseLetters = /[a-z]/g;
            if (event.target.value.match(lowerCaseLetters)) {
                letter.classList.remove("invalid");
                letter.classList.add("valid");

                letter_complete = true;
            } else {
                letter.classList.remove("valid");
                letter.classList.add("invalid");

                letter_complete = false;
            }

            var upperCaseLetters = /[A-Z]/g;
            if (event.target.value.match(upperCaseLetters)) {
                capital.classList.remove("invalid");
                capital.classList.add("valid");

                capital_complete = true;
            } else {
                capital.classList.remove("valid");
                capital.classList.add("invalid");

                capital_complete = false;
            }

            // Validate numbers
            var numbers = /[0-9]/g;
            if (event.target.value.match(numbers)) {
                number.classList.remove("invalid");
                number.classList.add("valid");

                number_complete = true;
            } else {
                number.classList.remove("valid");
                number.classList.add("invalid");

                number_complete = false;
            }

            // Validate length
            if (event.target.value.length >= 8) {
                length.classList.remove("invalid");
                length.classList.add("valid");

                length_complete = true;
            } else {
                length.classList.remove("valid");
                length.classList.add("invalid");

                length_complete = false;
            }

            //document.getElementById('input-password-validation-error').style.display = "none";

            if (
                (letter_complete == true) &&
                (number_complete == true) &&
                (capital_complete == true) &&
                (length_complete == true)
            ) {
                this.setState({ fullfilledPassword: true });
            }

            else
                this.setState({ fullfilledPassword: false });


            if (this.state.fullfilledPasswordConfirmation) {
                if (event.target.value.trim() != this.state.verificationcode) {
                    const flag = this.allFieldsFullFilled();

                    if (flag)
                        document.getElementById('submit-button-register').setAttribute('disabled', '');
                }
                else {
                    const flag = this.allFieldsFullFilled();

                    if (flag) {
                        if (
                            (letter_complete == true) &&
                            (number_complete == true) &&
                            (capital_complete == true) &&
                            (length_complete == true)
                        )
                            document.getElementById('submit-button-register').removeAttribute('disabled');
                    }

                    else
                        document.getElementById('submit-button-register').setAttribute('disabled', '');
                }
            }


            console.log(this.state.verificationcode);

        }

        console.log(event.target.value);
        this.setState({ password1: event.target.value });
    }

    getPass2(event) {
        console.log(event.target.value);

        this.setState({ password2: event.target.value });

        //H pano grammi den douleuei sync.
        if (this.state.password1.toString() != event.target.value) {
            this.setState({ errormsg: 1 })
        }
        else {
            console.log("OK");
            this.setState({ errormsg: 0 })
            document.getElementById('submit-button-register').removeAttribute('disabled');
        }
    }
    allFieldsFullFilled() {
        console.log(this.state.fullfilledPassword);
        if (
          (this.state.fullfilledPassword == true)
        ) {
          document.getElementById('submit-button-register').removeAttribute('disabled');
          return true;
        }
        else {
          document.getElementById('submit-button-register').setAttribute('disabled', '');
          return false;
        }
      }

    render() {
        return (
            <>
                <GenericHeader />
                {/* Page content */}
                <Container className="mt--7" fluid>
                    <Row>
                        <Col className="order-xl-1" xl="12">
                            <Card className="bg-secondary shadow">
                                <CardHeader className="bg-white border-0">
                                    <Row className="align-items-center">
                                        <Col xs="8">
                                            <h3 className="mb-0">Πληροφορίες χρήστη</h3>
                                        </Col>
                                        <Col className="text-right" xs="4">
                                            <Button
                                                color="primary"
                                                onClick={this.changePassword}
                                                size="sm"
                                                id="submit-button-register"
                                            >
                                                Αποθήκευση αλλαγών
                                            </Button>
                                        </Col>
                                    </Row>
                                </CardHeader>
                                <CardBody>
                                    <Form>
                                        <h6 className="heading-small text-muted mb-4">
                                            Αλλαγη κωδικου προσβασης
                                        </h6>
                                        <div className="pl-lg-4">
                                            <Row>
                                                <Col md="4"></Col>
                                                <Col md="4" style={{ alignTextAlign: "center" }}>
                                                    <FormGroup>
                                                        <label
                                                            className="form-control-label"
                                                            htmlFor="input-password-validation-error"
                                                        >
                                                            Νέος κωδικός πρόσβασης
                                                        </label>
                                                        <Input
                                                            className="form-control-alternative"
                                                            defaultValue=""
                                                            id="input-password-validation-error"
                                                            type="password"
                                                            onChange={this.getPass1}
                                                        />
                                                    </FormGroup>
                                                </Col>
                                                <div className='password_messages_container'>
                                                    <div id="message" style={{ width: '400px' }}>
                                                        <h3>Ο κωδικός πρέπει να περιέχει:</h3>
                                                        <p id="letter" className="invalid"><b>Πεζούς</b> χαρακτήρες</p>
                                                        <p id="capital" className="invalid"><b>Κεφαλαίους</b> χαρακτήρες</p>
                                                        <p id="number" className="invalid">Τουλάχιστον 1 <b>αριθμό</b></p>
                                                        <p id="length" className="invalid">Τουλάχιστον <b>8 ψηφία</b></p>
                                                    </div>
                                                </div>
                                            </Row>
                                            <Row>
                                                <Col md="4"></Col>
                                                <Col md="4" style={{ alignTextAlign: "center" }}>
                                                    <FormGroup>
                                                        <label
                                                            className="form-control-label"
                                                            htmlFor="input-address"
                                                        >
                                                            Επιβεβαίωση νέου κωδικού πρόσβασης
                                                        </label>
                                                        <Input
                                                            className="form-control-alternative"
                                                            defaultValue=""
                                                            id="input-address"
                                                            type="password"
                                                            onChange={this.getPass2}
                                                        />
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                            {this.state.errormsg ?
                                                <Row>
                                                    <Col md="4"></Col>
                                                    <Col md="4" style={{ alignTextAlign: "center" }}>
                                                        <p style={{ color: "red" }}>Οι Κωδικοί δεν ταιριαζουν.</p>
                                                    </Col>
                                                </Row> : <></>
                                            }
                                        </div>
                                    </Form>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>

                    {/* <Row>
                    <Col className="order-xl-1" xl="12">
                        <Card className="bg-secondary shadow">
                            <CardHeader className="bg-white border-0">
                            </CardHeader>
                            <CardBody>
                                <Form>
                                    <h6 className="heading-small text-muted mb-4">
                                    Επιτυχής αλλαγή κωδικού !
                                    </h6>

                                    <h6 className="heading-small text-muted mb-4">
                                    Υπήρξε πρόβλημα με την αλλαγή του κωδικού, πιθανώς δεν ταιριάζουν οι κωδικοί. Προσπαθήστε ξανά.
                                    </h6>
                                </Form>
                            </CardBody>
                        </Card>
                    </Col>
                </Row> */}
                </Container>
            </>
        );
    }
}

export default ConsumerSettings;
