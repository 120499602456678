import React from "react";
import { withRouter } from "react-router-dom";
import { Container, Row, Col, Nav, NavItem, NavLink } from "reactstrap";

class Footer extends React.Component {
  render() {
    const isSpecificPage = this.props.location.pathname.includes("newinstanceenomos");
    const footerClass = isSpecificPage ? "footer my-specific-page-footer" : "footer";

    return (
      <footer className={footerClass}>
        <Row className="align-items-center justify-content-xl-between">
          <Col xl="6">
            <div className="copyright text-center text-xl-left text-muted">
              Copyright © {new Date().getFullYear()}
            </div>
          </Col>

          <Col xl="6">
            <Nav className="nav-footer justify-content-right justify-content-xl-end">
              <NavItem>
                <NavLink
                  href="https://www.rae.gr/myraeterms/"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  Όροι και Προϋποθέσεις
                </NavLink>
              </NavItem>
            </Nav>
          </Col>
        </Row>
      </footer>
    );
  }
}

export default withRouter(Footer);
