import React, { useState } from "react";
import Select from 'react-select';
// node.js library that concatenates classes (strings)
import classnames from "classnames";

import { CommonLoading } from 'react-loadingg';


import axios from 'axios';

import {
  Button,
  Collapse,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  NavItem,
  NavLink,
  Nav,
  Progress,
  Table,
  Label,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col
} from "reactstrap";

import InstanceHeader from "components/Headers/InstanceHeader.js";
import GenericHeader from "components/Headers/GenericHeader.js";
import Accordion from 'react-bootstrap/Accordion';
import Moment from "moment";
import 'moment/locale/el';

const options_oikiakos = [
    { value: '1', label: 'Ζεστό νερό' },
    { value: '2', label: 'Θέρμανση χώρων' },
    { value: '3', label: 'Κουζίνα' }
  ];

const options_emporikos = [
    { value: '1', label: 'Φούρνος' },
    { value: '2', label: 'Καθαριστήριο' },
    { value: '3', label: 'Εστιατόριο' },
    { value: '4', label: 'Καφέ-Μπαρ' },
    { value: '5', label: 'Ξενοδοχείο' },
    { value: '6', label: 'Κατάστημα' },
    { value: '7', label: 'Γραφείο' },
    { value: '8', label: 'Γυμναστήριο' },
    { value: '9', label: 'Πρατήριο CNG' },
    { value: '10', label: 'Άλλο' }
  ];

  const options_dimosio_ktirio = [
    { value: '1', label: 'Νοσοκομείο' },
    { value: '2', label: 'Εκκλησία' },
    { value: '3', label: 'Σχολείο' },
    { value: '4', label: 'Κολυμβητήριο' },
    { value: '5', label: 'Δημοτικό Γυμναστήριο' },
    { value: '6', label: 'Άλλο' }
  ];

class CompleteApplication extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            error: null,
            activeNav: 1,
            loading: true,
            application: [],
            enabled: true,
            selectedClient: 0,
            selectedUse: '',
            selectedRole: 0,
            submitButton: 0,
            name: '',
            last: '',
            email: '',
            phone: ''
        };

        this.changeClient = this.changeClient.bind(this);
        this.changeUse = this.changeUse.bind(this);
        this.changeRole = this.changeRole.bind(this);
    }

    async componentDidMount() {
        const handle  = this.props.match.params.id;
        // console.log(handle);

        const result = await axios.get(process.env.REACT_APP_API_LINK + "/api/applications/check/" + handle + '/', {
            headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
        })
        .then(res => {
            this.setState({
                loading: false,
                application: res.data,
            });
            
            // console.log(res);
        })

        // const result1 = await axios.get(process.env.REACT_APP_API_LINK + "/api/workflowinstances/instancestep/" + handle + "/step/1/", {
        //     headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
        // })
        // .then(res => {
        //     this.setState({
        //         loading: false,
        //         actions: res.data
        //     })
        // })
    };

    changeClient(event) {
        // console.log(event.target.value);
		this.setState({selectedClient: event.target.value});
        // // console.log("selected client :" + this.state.selectedClient);

        if (event.target.value == 4) {
            this.state.submitButton = 1;
        }
	}

    changeUse = (e) =>{        
        let temp = '';
        for (let i = 0; i < e.length; i++) {
            temp += e[i].label + ' ';
        }

        this.setState({selectedUse: temp});
        this.state.selectedUse = temp;
        // console.log(this.state.selectedUse);  
    }

    changeRole(event) {
        // console.log(event.target.value);
		this.setState({selectedRole: event.target.value});
        
        if (event.target.value != 0) {
            this.state.submitButton = 1;
        }
	}

    updateName(evt) {
        // console.log(evt.target.value);
        this.setState({
          name: evt.target.value
        })
    }

    updateLastName(evt) {
        // console.log(evt.target.value);
        this.setState({
          last: evt.target.value
        })
    }

    updateEmail(evt) {
        // console.log(evt.target.value);
        this.setState({
          email: evt.target.value
        })
    }

    updatePhone(evt) {
        // console.log(evt.target.value);
        this.setState({
          phone: evt.target.value
        })
    }

    handleClick = stepStatus => {
        if (this.state.selectedClient != 4) {
            if (localStorage.getItem('token')) { 
                fetch(process.env.REACT_APP_API_LINK + '/api/applications/toinstance/', {
                    method: 'POST',
                    headers: new Headers({
                        'Authorization' : 'Bearer ' + localStorage.getItem('token'),
                    }),
                    body: JSON.stringify({
                        'Id' : this.state.application.Id,
                        'selectedClient' : this.state.selectedClient,
                        'selectedUse' : this.state.selectedUse,
                        'selectedRole' : this.state.selectedRole,
                        'Name' : this.state.name,
                        'LastName' : this.state.last,
                        'Email' : this.state.email,
                        'phoneNumber' : this.state.phone
                    })
                })
                .then(data => {
                    axios.get(process.env.REACT_APP_API_LINK + "/api/consumer/recent/instance/", {
                        headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
                    })
                    .then(res => {
                        // console.log(res.data);
                        setTimeout(function() {
                            window.location.replace("/consumer/instancedetails/" + res.data.Id);
                        }, 2500); 
                    })
                })
            }
        }
        else {

        }
    }

    render() {
        if (this.state.loading) {
            return (
                <>
                    <GenericHeader />
                    <CommonLoading />
                </>
            ) 
        }
        else {
            return (
            <>
                <InstanceHeader />
                <div className="header pb-4" style={{background: "linear-gradient(to right, #FFFFFF, #4A002B)"}}>
                    <Container fluid>
                        <div className="header-body">
                            <Row>
                                <Col lg="8" xl="12">
                                    <Card className="card-stats mb-4 mb-xl-0">
                                        <CardBody>
                                            <Row>
                                                <div className="col">
                                                    <CardTitle
                                                        tag="h5"
                                                        className="text-uppercase text-muted mb-0"
                                                    >
                                                    </CardTitle>
                                                        <span className="h2 font-weight-bold mb-0">
                                                        Φυσικό Αέριο
                                                        </span>
                                                    <CardBody>
                                                        <Row className="align-items-center"><span style={{fontWeight: "bold"}}>Διαθεσιμότητα Δικτύου: </span> {this.state.application.availability == 1 ? " Εντός Δικτύου" : " Εκτός Δικτύου"}</Row>
                                                        <Row className="align-items-center"><span style={{fontWeight: "bold"}}>Ημερομηνία Υποβολής: </span> {Moment(this.state.application.dateCreated).locale('el').format('dddd, DD MMM YYYY')}</Row>
                                                    </CardBody>
                                                </div>
                                            </Row>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                        </div>
                    </Container>
                </div>

                
                <Container className="mt-2" fluid>
                    <Row className="mt-5">
                        <Col className="order-xl-1" xl="12">
                            <Card className="bg-secondary shadow">
                                <CardHeader className="bg-white border-0">
                                        <Row className="align-items-center">
                                            <Col xs="8">
                                            Προσδιορισμός Πελάτη
                                            </Col>
                                            <Col className="text-right" xs="4">
                                                {this.state.submitButton == 1 ? 
                                                    <Button
                                                        color="success"
                                                        onClick={() => { this.handleClick() }}
                                                        size="sm"
                                                    >
                                                        Συμπληρωματικά στοιχεία αίτησης
                                                    </Button> :
                                                    <p></p>
                                                }
                                            </Col>
                                        </Row>
                                </CardHeader>
                                    <CardBody>
                                        <Form>
                                            <div className="pl-lg-4">
                                                <Row>
                                                    <Col lg="3">
                                                        <FormGroup>
                                                            <label
                                                                className="form-control-label"
                                                                htmlFor="consumer-client"
                                                            >
                                                                Κατηγορία Πελάτη
                                                            </label>
                                                            <Input
                                                                className="form-control-alternative"
                                                                id="consumer-client"
                                                                type="select"
                                                                onChange={this.changeClient}
                                                            >   
                                                                <option value='0'>-- Επιλέξτε κατηγορία πελάτη --</option>
                                                                <option value='1'>Οικιακός</option>
                                                                <option value='2'>Εμπορικός</option>
                                                                <option value='3'>Δημόσιο Κτίριο</option>
                                                                <option value='4'>Βιομηχανικός Πελάτης</option>
                                                            </Input>
                                                        </FormGroup>    
                                                    </Col>
                                                </Row>
                                                

                                                {this.state.selectedClient == 4 
                                                    ? 
                                                    <Row>
                                                        <Col lg="12">
                                                            <p style={{fontSize: "20px"}}><i><strong>Κάποιος εκπρόσωπός μας θα έρθει σύντομα σε επικοινωνία μαζί σας !</strong></i></p>
                                                        </Col>
                                                    </Row>
                                                    : 
                                                    <>
                                                        <Row>
                                                            <Col lg="3">
                                                                <FormGroup>
                                                                    <label
                                                                        className="form-control-label"
                                                                        htmlFor="consumer-user-home"
                                                                    >
                                                                        Προβλεπόμενη χρήση (Οικιακός)
                                                                    </label>
                                                                    {/* <Input
                                                                        className="form-control-alternative"
                                                                        id="consumer-user-home"
                                                                        type="select"
                                                                        onChange={this.changeUse}
                                                                        disabled={this.state.selectedClient != 1}
                                                                        isMulti
                                                                    >   
                                                                        <option value='0'>-- Επιλέξτε κατηγορία πελάτη --</option>
                                                                        <option value='1'>Ζεστό νερό</option>
                                                                        <option value='2'>Θέρμανση χώρων</option>
                                                                        <option value='3'>Κουζίνα</option>
                                                                    </Input> */}
                                                                    <Select options={options_oikiakos}
                                                                            isDisabled={this.state.selectedClient != 1}
                                                                            onChange={this.changeUse}
                                                                            className="form-control-alternative"
                                                                            id="consumer-user-home"
                                                                            placeholder="Επιλογή..."
                                                                            isMulti />
                                                                </FormGroup>
                                                            </Col>

                                                            <Col lg="3">
                                                                <FormGroup>
                                                                    <label
                                                                        className="form-control-label"
                                                                        htmlFor="consumer-use-commercial"
                                                                    >
                                                                        Προβλεπόμενη χρήση (Εμπορικός)
                                                                    </label>
                                                                    {/* <Input
                                                                        className="form-control-alternative"
                                                                        defaultValue="Lucky"
                                                                        id="consumer-use-commercial"
                                                                        type="select"
                                                                        onChange={this.changeUse}
                                                                        disabled={this.state.selectedClient != 2}
                                                                    >   
                                                                        <option value='0'>-- Επιλέξτε προβλεπόμενη χρήση --</option>
                                                                        <option value='1'>Φούρνος</option>
                                                                        <option value='2'>Καθαριστήριο</option>
                                                                        <option value='3'>Εστιατόριο</option>
                                                                        <option value='4'>Καφέ-Μπαρ</option>
                                                                        <option value='5'>Ξενοδοχείο</option>
                                                                        <option value='6'>Κατάστημα</option>
                                                                        <option value='7'>Γραφείο</option>
                                                                        <option value='8'>Γυμναστήριο</option>
                                                                        <option value='9'>Πρατήριο CNG</option>
                                                                        <option value='10'>Άλλο</option>
                                                                    </Input> */}
                                                                        <Select options={options_emporikos}
                                                                            isDisabled={this.state.selectedClient != 2}
                                                                            onChange={this.changeUse}
                                                                            className="form-control-alternative"
                                                                            id="consumer-user-home"
                                                                            placeholder="Επιλογή..."
                                                                            isMulti />
                                                                </FormGroup>
                                                            </Col>

                                                            <Col lg="3">
                                                                <FormGroup>
                                                                    <label
                                                                        className="form-control-label"
                                                                        htmlFor="consumer-use-public"
                                                                    >
                                                                        Προβλεπόμενη χρήση (Δημόσιο Κτίριο)
                                                                    </label>
                                                                    {/* <Input
                                                                        className="form-control-alternative"
                                                                        id="consumer-use-public"
                                                                        type="select"
                                                                        onChange={this.changeUse}
                                                                        disabled={this.state.selectedClient != 3}
                                                                    >   
                                                                        <option value='0'>-- Επιλέξτε προβλεπόμενη χρήση --</option>
                                                                        <option value='1'>Νοσοκομείο</option>
                                                                        <option value='2'>Εκκλησία</option>
                                                                        <option value='3'>Σχολείο</option>
                                                                        <option value='4'>Κολυμβητήριο</option>
                                                                        <option value='5'>Δημοτικό Γυμναστήριο</option>
                                                                        <option value='6'>Άλλο</option>
                                                                    </Input> */}
                                                                        <Select options={options_dimosio_ktirio}
                                                                            isDisabled={this.state.selectedClient != 3}
                                                                            onChange={this.changeUse}
                                                                            className="form-control-alternative"
                                                                            id="consumer-user-home"
                                                                            placeholder="Επιλογή..."
                                                                            isMulti />
                                                                    
                                                                </FormGroup>
                                                            </Col>
                                                        </Row>

                                                        <Row>
                                                            <Col lg="3">
                                                                <FormGroup>
                                                                    <label
                                                                        className="form-control-label"
                                                                        htmlFor="consumer-role"
                                                                    >
                                                                        Ιδιότητα Αιτούντος
                                                                    </label>
                                                                    <Input
                                                                        className="form-control-alternative"
                                                                        id="consumer-role"
                                                                        type="select"
                                                                        onChange={this.changeRole}
                                                                        disabled={this.state.selectedUse == 0}
                                                                    >   
                                                                        <option value='0'>-- Επιλέξτε ιδιότητα αιτούντος --</option>
                                                                        <option value='1'>Ιδιοκτήτης</option>
                                                                        <option value='2'>Ενοικιαστής</option>
                                                                        <option value='3'>Διαχειριστής</option>
                                                                        <option value='4'>Εξουσιοδοτημένος Εκπρόσωπος</option>
                                                                    </Input>
                                                                </FormGroup>
                                                            </Col>
                                                        </Row>
                                                    </>
                                                }
                                            </div>
                                        </Form>
                                    </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    
                    {this.state.selectedRole == 2 || this.state.selectedRole == 4 ? 
                        <Row className="mt-5">
                            <Col className="order-xl-1" xl="12">
                                <Card className="bg-secondary shadow">
                                    <CardHeader className="bg-white border-0">
                                        Στοιχεία Ιδιοκτήτη Ακινήτου
                                    </CardHeader>
                                    <CardBody>
                                        <Form>
                                            <div className="pl-lg-4">
                                                <Row className="align-items-center">
                                                    <Col lg="6">
                                                        <FormGroup>
                                                            <label
                                                                className="form-control-label"
                                                                htmlFor="input-username"
                                                            >
                                                                Όνομα Ιδιοκτήτη
                                                            </label>
                                                            <Input
                                                                className="form-control-alternative"
                                                                defaultValue=''
                                                                id=''
                                                                placeholder=''
                                                                type=''
                                                                disabled=''
                                                                onChange={evt => this.updateName(evt)}
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                    <Col lg="6">
                                                        <FormGroup>
                                                            <label
                                                                className="form-control-label"
                                                                htmlFor="input-username"
                                                            >
                                                                Επίθετο Ιδιοκτήτη
                                                            </label>
                                                            <Input
                                                                className="form-control-alternative"
                                                                defaultValue=''
                                                                id=''
                                                                placeholder=''
                                                                type=''
                                                                disabled=''
                                                                onChange={evt => this.updateLastName(evt)}
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                                <Row className="align-items-center">
                                                    <Col lg="6">
                                                        <FormGroup>
                                                            <label
                                                                className="form-control-label"
                                                                htmlFor="input-username"
                                                            >
                                                                Email Ιδιοκτήτη
                                                            </label>
                                                            <Input
                                                                className="form-control-alternative"
                                                                defaultValue=''
                                                                id=''
                                                                placeholder=''
                                                                type=''
                                                                disabled=''
                                                                onChange={evt => this.updateEmail(evt)}
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                    <Col lg="6">
                                                        <FormGroup>
                                                            <label
                                                                className="form-control-label"
                                                                htmlFor="input-username"
                                                            >
                                                                Τηλέφωνο Ιδιοκτήτη
                                                            </label>
                                                            <Input
                                                                className="form-control-alternative"
                                                                defaultValue=''
                                                                id=''
                                                                placeholder=''
                                                                type=''
                                                                disabled=''
                                                                onChange={evt => this.updatePhone(evt)}
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                            </div>
                                        </Form>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row> : <></> }
                    
                </Container>

                


                {/* {this.state.submitButton == 1 ? <Container className="mt-2" fluid>
                    <Row className="mt-5">
                        <Col className="order-xl-1" xl="12">
                            <Card className="bg-secondary shadow">
                                <CardHeader className="bg-white border-0">
                                    <Row className="align-items-center">
                                        <Col xs="8">
                                            <h3 className="mb-0">{dynamicData.step.Name}</h3>
                                        </Col>
                                        <Col className="text-right" xs="4">
                                            <Button
                                                color="primary"
                                                href="#pablo"
                                                onClick={() => { this.saveInstance(dynamicData.step.Status) }}
                                                size="sm"
                                            >
                                                Αποθήκευση
                                            </Button>
                                            <Button
                                                color="primary"
                                                href="#pablo"
                                                onClick={() => { this.submitInstance(dynamicData.step.Status) }}
                                                size="sm"
                                            >
                                                Υποβολή
                                            </Button>
                                        </Col>
                                    </Row>
                                </CardHeader>
                                <CardBody>
                                    <Form>
                                        <div className="pl-lg-4">
                                            <Row>
                                            {dynamicData.actions.map((dynamicActions) =>
                                                <Col lg="6">
                                                    <FormGroup>
                                                        <label
                                                            className="form-control-label"
                                                            htmlFor="input-username"
                                                        >
                                                            {dynamicActions.action.Name}
                                                        </label>
                                                        { dynamicActions.action.Type == 'file' ? 
                                                            <>
                                                                <Input
                                                                    className="form-control-alternative"
                                                                    id={dynamicData.step.Id + "/" + dynamicActions.action.Id}
                                                                    type={dynamicActions.action.Type}
                                                                    disabled={!this.state.enabled}
                                                                    onChange={evt => this.updateFile(evt, dynamicActions.data.Data)}
                                                                />
                                                                <Button
                                                                    color="primary"
                                                                    onClick={evt => this.uploadFile(dynamicData.step.Id + "/" + dynamicActions.action.Id)}
                                                                    size="sm"
                                                                >
                                                                    Υποβολή
                                                                </Button>

                                                                <Table className="align-items-center table-flush" responsive>
                                                                    <thead className="thead-light">
                                                                        <tr>
                                                                            <th scope="col">Ονομα</th>
                                                                            <th scope="col">Ημερομηνια Υποβολης</th>
                                                                            <th scope="col">Ενεργειες</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                    {
                                                                        this.state.files.map((dynamicFiles) => {
                                                                            {dynamicFiles ? 
                                                                                dynamicActions.action.Id == dynamicFiles.ActionId ?
                                                                                <tr>
                                                                                    <td>{dynamicFiles.FileName}</td>
                                                                                    <td>{dynamicFiles.UploadDate}</td>
                                                                                    <td><Button
                                                                                        color="primary"
                                                                                        href={dynamicFiles.Link}
                                                                                        size="sm"
                                                                                        title="Προβολή"
                                                                                    >
                                                                                        <i className="fas fa-eye" />
                                                                                    </Button> </td>
                                                                                </tr> : <></>
                                                                                : <></>
                                                                            }
                                                                        }) 
                                                                    }
                                                                    </tbody>
                                                                </Table>
                                                            </>
                                                        : <>{ dynamicActions.action.Type == 'button' ?
                                                            <>
                                                                <Button
                                                                    color="primary"
                                                                    onClick={evt => this.dynamicCall(dynamicActions.data.Data)}
                                                                    size="sm"
                                                                >
                                                                    {dynamicActions.action.Description}
                                                                </Button>
                                                                {this.state.buttonCondition ? <p>Περίπτωση Α</p> : <p>Περίπτωση B</p> }
                                                            </>
                                                            : <>{ dynamicActions.action.Type == 'paragraph' ?
                                                                    <>
                                                                        <p></p>
                                                                    </>
                                                                :
                                                                    <Input
                                                                        className="form-control-alternative"
                                                                        defaultValue={ dynamicActions.data.Data }
                                                                        id={dynamicData.step.Id + "/" + dynamicActions.action.Id}
                                                                        placeholder={dynamicActions.action.Description}
                                                                        type={dynamicActions.action.Type}
                                                                        disabled={!this.state.enabled}
                                                                        onChange={evt => this.updateInputValue(evt)}
                                                                    />
                                                                }
                                                                </>
                                                            }</>
                                                        }
                                                            <Button
                                                            color="primary"
                                                            onClick={evt => this.handleOpenModal(dynamicData.step.Id + "/" + dynamicActions.action.Id, dynamicActions.action.Name)}
                                                            size="sm"
                                                        >
                                                            Προσθήκη σχολίων
                                                        </Button>
                                                    </FormGroup>
                                                </Col>
                                            )}
                                            </Row>
                                        </div>
                                    </Form>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>  : <p></p>} */}
            </>
            );
        }
    }
}

export default CompleteApplication;
