import React from "react";
// node.js library that concatenates classes (strings)
import classnames from "classnames";

import axios from 'axios';

import {
    Button,
    Card,
    CardHeader,
    CardBody,
    NavItem,
    NavLink,
    Nav,
    Progress,
    Table,
    Container,
    Row,
    Col
} from "reactstrap";

import GenericHeader from "components/Headers/GenericHeader.js";
// import Spinner from "views/examples/Spinner.js";
import Moment from "moment";
import { useState } from "react";
import 'moment/locale/el';

import { MDBDataTable } from 'mdbreact';

import '../../../assets/css/datatable.css';

import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";

import ReactHTMLTableToExcel from 'react-html-table-to-excel';

import Multiselect from 'multiselect-react-dropdown';
import { ThemeProvider } from "react-bootstrap";
import { ThreeHorseLoading } from "react-loadingg";



const chbx = [
    // { id: 13, text: "Νέα " },
    { id: 110, text: "Νέα & Ανακοινώσεις " },
    { id: 28, text: "Αποφάσεις " },
    { id: 109, text: "Διαβουλεύσεις " },
];

class mailinglist extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            error: null,
            RAEpostsTitle: [],
            RAEpostsUrl: [],
            RAEpostsContent: [],
            posts: [],
            dummy: [],
            selectedCheckboxes: [],
            selectedCheckboxesOldPosts: [13, 110, 28, 109],
            selectedTags: [],
            precheckedTags: [],
            checkboxes: [],
            lastEmails: [],
            tags: [],
            flag: false
        };

    }





    async componentDidMount() {
        // this.fetchRAEposts();

        if (localStorage.getItem('token')) {
            await fetch(process.env.REACT_APP_API_LINK + "/api/auth/user/", {
                method: 'get',
                headers: new Headers({
                    'Authorization': 'Bearer ' + localStorage.getItem('token'),
                    'Content-Type': 'application/x-www-form-urlencoded'
                })
            })
                .then(res => res.json())
                .then((result) => {
                    console.log(result);


                    this.setState({
                        userDetails: result
                    });

                    // console.log(document.getElementById("saveButton"));
                    if (result.Email.includes("@facebook.com") || result.Email.includes("@google.com")) {
                        document.getElementById("saveButton").setAttribute("disabled", "true");
                        document.getElementById("facebook-google-alert").style.display = 'block';
                    }


                })

           


            await fetch("https://rae.gr/wp-json/wp/v2/tags/?_fields[]=name&_fields[]=id&per_page=100", {
                method: 'get',
                // headers: new Headers({
                //     'Authorization': 'Bearer ' + localStorage.getItem('token'),
                //     'Content-Type': 'application/x-www-form-urlencoded'
                // })
            })
                .then(res => res.json())
                .then((result) => {
                    console.log(result);
                    var tags_array = [];

                    result.forEach((tag, index) => {
                        // console.log(name.name);
                        tags_array.push({
                            key: tag.name.replace("&nbsp;", " "),
                            id: tag.id
                        });

                    }, result);

                    this.setState({
                        tags: tags_array
                    })


                    // this.state.tags = tags_array;

                    fetch(process.env.REACT_APP_API_LINK + "/api/mailinglist/tags/" + this.state.userDetails.Id + "/", {
                        method: 'get',
                        headers: new Headers({
                            'Authorization': 'Bearer ' + localStorage.getItem('token'),
                            'Content-Type': 'application/x-www-form-urlencoded'
                        })
                    })
                        .then(res => res.json())
                        .then((result) => {

                            console.log(result);
                            var res_list = [];


                            // console.log(result, this.state.tags);
                            let temp_list = [...tags_array];
                            console.log(temp_list.length);

                            console.log(result);

                            temp_list.forEach((element, index) => {

                                let flag = result.includes(String(element.id));

                                if (!flag)
                                    delete temp_list[index];
                                else
                                    res_list.push(element);

                            }, temp_list);


                            console.log(this.state.selectedTags);
                            // this.state.selectedTags = [...res_list];

                            this.setState({
                                precheckedTags: res_list,
                                selectedTags: [...result]
                            })

                        })


                })
        }

        var self = this;

        await fetch(process.env.REACT_APP_API_LINK + "/api/mailinglist/categories/" + this.state.userDetails.Id + "/", {
            method: 'get',
            headers: new Headers({
                'Authorization': 'Bearer ' + localStorage.getItem('token'),
                'Content-Type': 'application/x-www-form-urlencoded'
            })
        })
            .then(res => res.json())
            .then((result) => {

                var temp_list = [];
                for (const id of result) {
                    temp_list.push(parseInt(id));
                    // self.state.selectedCheckboxes.push(parseInt(id));
                }

                this.setState({
                    checkboxes: chbx,
                    selectedCheckboxes: temp_list
                })
            })

        // this.fetchRAEposts();





    }

    updateRAEmailinglist = async (categories) => {
        console.log(this.state.selectedCheckboxes);
        console.log(this.state.selectedTags);
        await fetch(process.env.REACT_APP_API_LINK + "/api/mailinglist/updateconsumermailinglist/", {
            method: 'put',
            headers: new Headers({
                'Authorization': 'Bearer ' + localStorage.getItem('token'),
                // 'Content-Type': 'application/x-www-form-urlencoded'
            }),
            body: JSON.stringify({
                "ConsumerId": this.state.userDetails.Id,
                "Categories": this.state.selectedCheckboxes,
                "Tags": this.state.selectedTags
            })
            //   body: JSON.stringify(this.state.userDetails.Id)
        })
            .then(res => res.json())
            .then((result) => {
                console.log(result);
                window.location.reload();
            })
    }

    onChange = id => {

        var selectedCheckboxes = [...this.state.selectedCheckboxes];


        // console.log(id);
        // Find index
        const findIdx = selectedCheckboxes.indexOf(id);

        // Index > -1 means that the item exists and that the checkbox is checked
        // and in that case we want to remove it from the array and uncheck it

        if (findIdx > -1) {
            selectedCheckboxes.splice(findIdx, 1);
        } else {
            if (!selectedCheckboxes.includes(id)) {
                selectedCheckboxes.push(id);
            }

        }

        console.log(selectedCheckboxes);        

        this.setState({
            selectedCheckboxes: selectedCheckboxes
        });

        // this.state.selectedCheckboxes = selectedCheckboxes;
        console.log(this.state)
    };

    // onChangeOldPosts = id => {

    //     const selectedCheckboxes = this.state.selectedCheckboxesOldPosts;

    //     // console.log(id);
    //     // Find index
    //     const findIdx = selectedCheckboxes.indexOf(id);

    //     // Index > -1 means that the item exists and that the checkbox is checked
    //     // and in that case we want to remove it from the array and uncheck it

    //     if (findIdx > -1) {
    //         selectedCheckboxes.splice(findIdx, 1);
    //     } else {
    //         if (!selectedCheckboxes.includes(id)) {
    //             selectedCheckboxes.push(id);
    //         }

    //     }
    //     this.state.selectedCheckboxesOldPosts = selectedCheckboxes;
    //     this.fetchRAEwordpressdata(this.state.startDate, this.state.otherDay);


    //     console.log(this.state)
    // };

    saveChanges = () => {
        this.updateRAEmailinglist();

    }

    checkFacebookOrGoogle = () => {
        if (this.state.userDetails.Email.includes("@facebook.com") || this.state.userDetails.Email.includes("@google.com"))
            return true;
        return false;
    }

    multiselect_onchange = (e) => {
        let tags_id_list = [];

        console.log(e);
        e.forEach((tag, index) => {
            tags_id_list.push(tag.id);
        });

        this.state.selectedTags = tags_id_list;
    }

    render() {
        return (
            <>
                <GenericHeader />
                <Container className="mt--8" fluid>
                    <Row className="mt-5">
                        <Col className="mb-5 mb-xl-0" xl="12">
                            <Card className="shadow">
                                <CardHeader className="border-0">
                                    <Row className="align-items-center">
                                        <div className="col">
                                            <h2 className="mb-0">Mailing List</h2>
                                        </div>
                                    </Row>

                                </CardHeader>
                                <CardHeader className="border-0">
                                    <Row className="align-items-center">
                                        <div className="col">
                                            <h3 style={{ paddingBottom: '25px' }} className="mb-0">Επιθυμώ να ενημερώνομαι για: </h3>
                                            <div className="alert alert-primary" id={"facebook-google-alert"} role="alert" style={{ display: 'none' }}>
                                                Για τους χρήστες που έχουν συνδεθεί με Facebook, πρέπει να οριστεί διεύθυνση email στην οποία θα λαμβάνουν τα ενημερωτικά email.
                                                Για να ορίσετε διεύθυνση email, παρακαλούμε μεταβείτε στην επεξεργασία προφίλ! <br />
                                                <Button
                                                    // color="success"
                                                    href="/consumer/profile"
                                                    style={{ marginTop: '10px' }}
                                                    size="sm"
                                                    title="Διαγραφή"
                                                >
                                                    <i className="fas fa-user-circle"></i>
                                                    &nbsp;Επεξεργασία Προφίλ
                                                </Button>
                                            </div>
                                            {this.state.checkboxes.map(checkbox => (
                                                <div className="col-md-12">

                                                    <label key={checkbox.id}>
                                                        <input
                                                            type="checkbox"
                                                            defaultChecked={this.state.selectedCheckboxes.includes(checkbox.id)}
                                                            onChange={() => this.onChange(checkbox.id)}
                                                            selected={this.state.selectedCheckboxes.includes(checkbox.id)}
                                                            disabled={this.checkFacebookOrGoogle()}
                                                        /> &nbsp;
                                                        {checkbox.text}
                                                    </label>
                                                </div>
                                            ))}
                                            {/* {this.state.precheckedTags ?
                                                <>
                                                    {console.log(this.state.precheckedTags)} */}
                                                    <div style={{ marginTop: '10px', width: '60%' }}>
                                                        <Multiselect
                                                            displayValue="key"
                                                            onKeyPressFn={function noRefCheck() { }}
                                                            onRemove={e => { this.multiselect_onchange(e) }}
                                                            onSearch={function noRefCheck() { }}
                                                            onSelect={e => { this.multiselect_onchange(e) }}
                                                            options={this.state.tags}
                                                            selectedValues={this.state.precheckedTags}
                                                            placeholder={"Επιλέξτε λέξεις κλειδιά"}
                                                        />
                                                    </div>
                                                {/* </>
                                                :
                                                <></>
                                            } */}



                                        </div>



                                    </Row>
                                    <Button
                                        color="success"
                                        onClick={() => this.updateRAEmailinglist()}
                                        style={{ marginTop: '20px' }}
                                        size="sm"
                                        title="Διαγραφή"
                                        id={"saveButton"}
                                    >
                                        <i className="fas fa-check" />
                                        &nbsp;Αποθήκευση Αλλαγών
                                    </Button>

                                </CardHeader>
                            </Card>
                        </Col>
                    </Row>
                </Container>

            </>
        );
    }
}

export default mailinglist;