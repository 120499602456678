import React from 'react';
import { Input, Col } from 'reactstrap';
import ReactTooltip from 'react-tooltip';

const TextAreaActionComponent = ({ dynamicData, dynamicActions, updateInputValue }) => {
  return (
    <React.Fragment>
      &nbsp;<i className="fa fa-info-circle" data-tip data-for={'info-textarea' + dynamicData.step.Id + dynamicActions.action.Id} aria-hidden="true"></i>
      <ReactTooltip id={'info-textarea' + dynamicData.step.Id + dynamicActions.action.Id} type='info'>
        <span>{dynamicActions.action.Description}</span>
      </ReactTooltip>
      <Col md={8}>
        <Input
          className="form-control-alternative"
          defaultValue={dynamicActions.data.Data}
          id={dynamicData.step.Id + "/" + dynamicActions.action.Id}
          placeholder={dynamicActions.action.Description}
          type='textarea'
          disabled={!dynamicActions.action.Required}
          onChange={evt => updateInputValue(evt)}
        />
      </Col>
    </React.Fragment>
  );
};

export default TextAreaActionComponent;
